import { Box } from "@material-ui/core";

export default function MailboxIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg width="210" height="190" viewBox="10 20 210 190" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M205.815 127.534C205.663 126.741 205.503 125.956 205.336 125.181C201.159 105.532 192.746 85.24 177.748 71.3731C171.25 65.3767 163.393 60.7939 154.638 59.1432C143.949 57.1322 132.837 59.9184 123.014 64.6152C113.192 69.312 104.378 75.7963 95.2758 81.7516C89.398 85.5957 83.1827 89.2984 76.2469 90.4156C67.9432 91.7517 59.4479 89.2437 51.0712 90.0417C37.9384 91.2912 26.3651 101.086 21.6318 113.403C16.8985 125.719 18.6131 140.015 24.8512 151.643C31.0893 163.271 41.4952 172.327 53.2782 178.296C69.5802 186.577 88.8462 189.167 106.585 184.68C115.249 182.491 123.612 178.661 132.531 178.501C143.594 178.305 153.963 183.777 164.907 185.291C175.194 186.709 186.166 184.379 194.351 177.995C209.531 166.139 209.103 144.616 205.815 127.534Z" fill="#333333" />
        <path opacity="0.7" d="M205.815 127.534C205.663 126.741 205.503 125.956 205.336 125.181C201.159 105.532 192.746 85.24 177.748 71.3731C171.25 65.3767 163.393 60.7939 154.638 59.1432C143.949 57.1322 132.837 59.9184 123.014 64.6152C113.192 69.312 104.378 75.7963 95.2758 81.7516C89.398 85.5957 83.1827 89.2984 76.2469 90.4156C67.9432 91.7517 59.4479 89.2437 51.0712 90.0417C37.9384 91.2912 26.3651 101.086 21.6318 113.403C16.8985 125.719 18.6131 140.015 24.8512 151.643C31.0893 163.271 41.4952 172.327 53.2782 178.296C69.5802 186.577 88.8462 189.167 106.585 184.68C115.249 182.491 123.612 178.661 132.531 178.501C143.594 178.305 153.963 183.777 164.907 185.291C175.194 186.709 186.166 184.379 194.351 177.995C209.531 166.139 209.103 144.616 205.815 127.534Z" fill="black" />
        <path d="M173.832 79.3442C172.35 78.5325 171.675 79.2074 171.137 78.5325C170.599 77.8576 172.619 76.1066 168.574 74.3555C168.574 74.3555 168.305 70.5799 162.779 70.4476C157.252 70.3154 158.734 74.2187 157.252 75.1627C155.77 76.1066 155.364 74.6246 153.882 75.5685C152.4 76.5124 153.075 78.4003 151.862 78.6693C150.649 78.9383 149.03 77.4563 147.548 78.4003C146.066 79.3442 146.066 80.1513 145.391 80.1513C144.717 80.1513 143.367 79.6132 142.56 80.1513C141.753 80.6894 140.809 81.3643 140.809 81.3643H174.913C174.913 81.3643 175.314 80.1285 173.832 79.3442Z" fill="white" />
        <path opacity="0.5" d="M173.832 79.3442C172.35 78.5325 171.675 79.2074 171.137 78.5325C170.599 77.8576 172.619 76.1066 168.574 74.3555C168.574 74.3555 168.305 70.5799 162.779 70.4476C157.252 70.3154 158.734 74.2187 157.252 75.1627C155.77 76.1066 155.364 74.6246 153.882 75.5685C152.4 76.5124 153.075 78.4003 151.862 78.6693C150.649 78.9383 149.03 77.4563 147.548 78.4003C146.066 79.3442 146.066 80.1513 145.391 80.1513C144.717 80.1513 143.367 79.6132 142.56 80.1513C141.753 80.6894 140.809 81.3643 140.809 81.3643H174.913C174.913 81.3643 175.314 80.1285 173.832 79.3442Z" fill="white" />
        <path d="M116.075 168.72C114.588 167.908 113.918 168.583 113.375 167.908C112.833 167.234 114.857 165.483 110.817 163.732C110.817 163.732 110.548 159.956 105.022 159.824C99.4948 159.691 100.977 163.595 99.4948 164.539C98.0128 165.483 97.607 164.001 96.125 164.944C94.643 165.888 95.3133 167.776 94.1003 168.045C92.8874 168.314 91.2686 166.832 89.7866 167.776C88.3046 168.72 88.3046 169.527 87.6297 169.527C86.9548 169.527 85.6096 168.989 84.7979 169.527C83.9862 170.065 83.0469 170.74 83.0469 170.74H117.151C117.151 170.74 117.557 169.504 116.075 168.72Z" fill="white" />
        <path opacity="0.5" d="M116.075 168.72C114.588 167.908 113.918 168.583 113.375 167.908C112.833 167.234 114.857 165.483 110.817 163.732C110.817 163.732 110.548 159.956 105.022 159.824C99.4948 159.691 100.977 163.595 99.4948 164.539C98.0128 165.483 97.607 164.001 96.125 164.944C94.643 165.888 95.3133 167.776 94.1003 168.045C92.8874 168.314 91.2686 166.832 89.7866 167.776C88.3046 168.72 88.3046 169.527 87.6297 169.527C86.9548 169.527 85.6096 168.989 84.7979 169.527C83.9862 170.065 83.0469 170.74 83.0469 170.74H117.151C117.151 170.74 117.557 169.504 116.075 168.72Z" fill="white" />
        <path d="M88.519 153.672C86.1979 152.409 85.1446 153.463 84.301 152.409C83.4574 151.356 86.6175 148.611 80.2927 145.87C80.2927 145.87 79.8732 139.965 71.2229 139.755C62.5726 139.546 64.8982 145.66 62.5589 147.138C60.2196 148.615 59.6086 146.294 57.2875 147.767C54.9665 149.24 56.0199 152.2 54.1229 152.619C52.2259 153.039 49.6951 150.722 47.3741 152.199C45.0531 153.677 45.0531 154.935 43.9997 154.935C42.9463 154.935 40.8351 154.092 39.5719 154.935C38.3088 155.779 36.8359 156.832 36.8359 156.832H90.1879C90.1879 156.832 90.8355 154.954 88.519 153.672Z" fill="white" />
        <path opacity="0.5" d="M88.519 153.672C86.1979 152.409 85.1446 153.463 84.301 152.409C83.4574 151.356 86.6175 148.611 80.2927 145.87C80.2927 145.87 79.8732 139.965 71.2229 139.755C62.5726 139.546 64.8982 145.66 62.5589 147.138C60.2196 148.615 59.6086 146.294 57.2875 147.767C54.9665 149.24 56.0199 152.2 54.1229 152.619C52.2259 153.039 49.6951 150.722 47.3741 152.199C45.0531 153.677 45.0531 154.935 43.9997 154.935C42.9463 154.935 40.8351 154.092 39.5719 154.935C38.3088 155.779 36.8359 156.832 36.8359 156.832H90.1879C90.1879 156.832 90.8355 154.954 88.519 153.672Z" fill="white" />
        <path d="M112.87 32.6357C96.9508 32.6357 84.005 44.2501 83.5535 58.7281L71.009 65.6L64.4152 59.3345L54.8939 73.7897L48.2637 73.507L51.6654 86.6991L51.9618 75.8417L56.6541 76.7218L64.4061 68.564L72.3177 74.8294L84.7163 67.0774C88.2914 78.2221 99.5318 86.3662 112.861 86.3662C129.062 86.3662 142.2 74.3369 142.2 59.4987C142.2 44.6605 129.071 32.6357 112.87 32.6357Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M138.857 42.0337H85.9339C84.4656 42.0337 83.2754 43.2239 83.2754 44.6922V74.8064C83.2754 76.2746 84.4656 77.4649 85.9339 77.4649H138.857C140.325 77.4649 141.516 76.2746 141.516 74.8064V44.6922C141.516 43.2239 140.325 42.0337 138.857 42.0337Z" fill="#5AC994" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M114.721 58.8648L141.516 45.3079V44.6878C141.516 43.9827 141.236 43.3065 140.737 42.808C140.239 42.3094 139.562 42.0293 138.857 42.0293H85.9339C85.2288 42.0293 84.5526 42.3094 84.054 42.808C83.5555 43.3065 83.2754 43.9827 83.2754 44.6878V45.4219L109.582 58.9241C110.384 59.3142 111.267 59.5119 112.159 59.5016C113.051 59.4914 113.928 59.2733 114.721 58.8648Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M140.488 55.2321C143.747 54.7008 145.958 51.6285 145.427 48.3699C144.895 45.1112 141.823 42.9003 138.564 43.4316C135.306 43.9629 133.095 47.0352 133.626 50.2938C134.157 53.5524 137.23 55.7634 140.488 55.2321Z" fill="#333333" />
        <path d="M139.167 52.7726C139.014 52.5857 138.937 52.3479 138.952 52.1069V46.7854L137.904 47.4238C137.821 47.4787 137.724 47.5073 137.625 47.5058C137.556 47.5075 137.487 47.4931 137.423 47.4638C137.36 47.4346 137.304 47.3912 137.261 47.3371C137.21 47.286 137.171 47.2254 137.144 47.1588C137.118 47.0923 137.104 47.0212 137.106 46.9495C137.107 46.8551 137.134 46.7629 137.184 46.6826C137.234 46.6024 137.304 46.537 137.388 46.4935L139.299 45.3672C139.436 45.2804 139.593 45.2316 139.755 45.2258C139.854 45.2231 139.953 45.2425 140.043 45.2827C140.134 45.323 140.215 45.383 140.279 45.4584C140.423 45.6461 140.494 45.8792 140.48 46.115V52.1434C140.491 52.3742 140.415 52.6007 140.266 52.7772C140.197 52.8542 140.113 52.9153 140.018 52.9563C139.924 52.9973 139.821 53.0171 139.718 53.0143C139.614 53.0195 139.51 53.0004 139.414 52.9585C139.318 52.9165 139.234 52.8529 139.167 52.7726Z" fill="#5AC994" />
        <path d="M83.6758 128.483C83.6758 128.483 101.688 155.615 106.02 156.071C110.352 156.527 124.032 142.391 124.032 142.391L120.156 141.479L107.246 150.206C107.246 150.206 96.2158 136.663 86.5212 126.312L83.6758 128.483Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M86.8826 120.384C86.8826 120.384 90.4942 120.539 90.3391 122.113C90.1841 123.686 90.3391 127.927 89.3952 128.715C88.4513 129.504 87.0377 129.814 86.723 128.259C86.4084 126.704 86.8826 120.384 86.8826 120.384Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M85.9387 127.785C85.9387 127.785 85.7791 132.5 85.4827 132.97C85.1863 133.44 82.9656 135.172 81.552 134.858C80.1384 134.543 80.6081 133.129 80.6081 132.345V119.928H85.9524L85.9387 127.785Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M78.5511 129.504C78.5511 129.504 77.4521 131.547 78.3915 133.12C79.3309 134.693 82.0076 134.693 83.1111 133.904C84.2146 133.116 83.4212 132.021 82.4818 131.707C81.5425 131.392 80.2793 130.603 80.2793 129.664C80.2793 128.724 79.0208 128.884 78.5511 129.504Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M77.6973 126.522C77.6973 127.598 77.8797 129.404 78.7278 130.17C79.6854 131.014 84.0904 131.082 86.0238 129.791C87.9573 128.501 86.6713 126.28 85.2076 126.422C83.9946 126.54 82.695 126.244 81.4638 126.089C80.7707 126.002 78.3265 125.459 77.8477 125.966C77.739 126.124 77.6919 126.317 77.7155 126.508L77.6973 126.522Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M77.1552 122.627C77.0047 123.713 76.9363 125.546 77.8255 126.339C78.8332 127.251 84.0544 127.507 86.5214 126.303C88.9884 125.099 87.8164 122.787 86.0654 122.864C84.6108 122.924 83.1105 122.559 81.6787 122.345C80.8716 122.226 78.0307 121.565 77.406 122.053C77.259 122.196 77.1725 122.39 77.1643 122.595L77.1552 122.627Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M77.4616 117.256C77.074 118.282 76.5998 120.056 77.2929 121.027C78.0818 122.126 83.1115 123.539 85.7791 122.91C88.4467 122.281 87.8265 119.768 86.0937 119.454C84.6619 119.194 83.2802 118.505 81.9304 117.981C81.1689 117.684 78.5651 116.417 77.8264 116.75C77.6564 116.852 77.5311 117.015 77.4753 117.206L77.4616 117.256Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M119.275 196.062L133.639 142.363H138.5L123.252 196.504" fill="#333333" />
        <path d="M119.275 196.062L133.639 142.363H138.5L123.252 196.504" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M128.788 190.927L142.7 142.145L138.501 142.363L123.252 196.504" fill="white" />
        <path d="M128.788 190.927L142.7 142.145L138.501 142.363L123.252 196.504" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M127.451 195.619L128.094 193.367" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M109.668 154.976C109.668 154.976 111.241 157.334 113.129 157.648C115.017 157.963 134.976 142.245 134.976 142.245L126.8 143.184L109.668 154.976Z" fill="white" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M96.9961 129.769C96.9961 129.769 100.754 98.6102 109.153 87.7802C118.332 75.9242 136.778 85.5731 133.02 104.575C130.138 119.167 121.748 145.236 121.748 145.236L96.9961 129.769Z" fill="#5AC994" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M123.959 83.8037L173.061 99.3077C174.669 99.8072 176.139 100.672 177.357 101.834C180.453 104.816 185.072 111.232 181.192 120.694C178.05 128.139 173.804 135.067 168.597 141.246L121.752 145.236L131.255 108.551C131.255 108.551 135.669 92.4221 123.959 83.8037Z" fill="white" />
        <path d="M146.982 126.964C135.172 132.5 128.856 131.524 125.715 129.928L121.752 145.236L168.601 141.26C173.808 135.081 178.054 128.152 181.196 120.708C181.706 119.475 182.094 118.195 182.354 116.887C170.968 114.205 161.939 119.955 146.982 126.964Z" fill="#5AC994" />
        <path opacity="0.5" d="M146.982 126.964C135.172 132.5 128.856 131.524 125.715 129.928L121.752 145.236L168.601 141.26C173.808 135.081 178.054 128.152 181.196 120.708C181.706 119.475 182.094 118.195 182.354 116.887C170.968 114.205 161.939 119.955 146.982 126.964Z" fill="white" />
        <path d="M123.959 83.8037L173.061 99.3077C174.669 99.8072 176.139 100.672 177.357 101.834C180.453 104.816 185.072 111.232 181.192 120.694C178.05 128.139 173.804 135.067 168.597 141.246L121.752 145.236L131.255 108.551C131.255 108.551 135.669 92.4221 123.959 83.8037Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M151.555 88.4277L143.867 112.422C142.558 112.637 141.282 113.717 140.648 115.309C139.772 117.507 140.452 119.846 142.166 120.53C143.881 121.214 145.983 119.983 146.859 117.794C147.579 115.97 147.246 114.082 146.147 113.084L151.555 94.6065L159.098 97.1784L161.159 91.866L151.555 88.4277Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M104.574 109.686C104.574 109.686 108.003 113.462 108.177 115.345C108.35 117.228 105.263 122.185 105.263 122.185C105.263 122.185 111.606 126.129 117.607 123.384C123.608 120.639 126.521 108.984 126.521 108.984C126.521 108.984 119.321 112.413 116.576 112.413C113.831 112.413 104.574 109.686 104.574 109.686Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M117.607 123.403C119.262 122.646 120.68 121.21 121.87 119.531C121.785 119.169 121.596 118.84 121.327 118.584C121.057 118.328 120.718 118.156 120.352 118.09C116.749 117.402 112.978 121.346 112.978 121.346C112.64 122.317 112.435 123.33 112.367 124.356C114.165 124.466 115.963 124.139 117.607 123.403Z" fill="#5AC994" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M112.631 120.32C110.699 120.246 108.811 119.725 107.114 118.797C106.279 120.558 105.258 122.204 105.258 122.204C105.258 122.204 111.601 126.148 117.602 123.403C119.221 122.575 120.607 121.353 121.633 119.851C120.228 120.006 115.65 120.462 112.631 120.32Z" fill="white" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M116.576 112.431C113.84 112.431 104.574 109.695 104.574 109.695C105.696 110.94 106.698 112.288 107.566 113.722C109.846 114.634 113.854 116.043 117.091 116.043C119.877 116.017 122.598 115.206 124.944 113.703C125.567 112.164 126.094 110.588 126.521 108.984C126.521 108.984 119.321 112.431 116.576 112.431Z" fill="white" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M115.893 104.543C115.893 104.543 116.923 100.945 119.837 100.598C122.751 100.252 123.608 102.313 123.608 102.313C123.608 102.313 118.104 102.313 115.893 104.543Z" fill="#333333" />
        <path d="M124.291 107.972L128.577 109.518" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M111.076 101.095C110.862 102.304 110.31 103.22 109.841 103.138C109.371 103.056 109.175 102.007 109.385 100.794C109.594 99.5813 110.155 98.6647 110.62 98.7468C111.085 98.8289 111.295 99.864 111.076 101.095Z" fill="#333333" />
        <path d="M116.576 100.256C116.576 100.256 116.407 98.1994 120.863 98.1994C125.318 98.1994 126.18 97.6841 124.465 95.4634C122.75 93.2427 115.55 94.4283 116.576 100.256Z" fill="#333333" />
        <path d="M114.862 94.0866C114.862 94.0866 114.52 91.1682 111.78 91.1682C109.039 91.1682 107.489 94.4286 107.32 93.3981C107.151 92.3675 108.177 88.7697 111.948 88.4277C115.72 88.0857 114.862 94.0866 114.862 94.0866Z" fill="#333333" />
        <path d="M134.21 91.7061C134.945 93.5524 135.391 95.5012 135.532 97.4836C136.626 109.992 125.5 144.087 125.5 144.087" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M129.9 86.6904C130.892 87.1438 131.758 87.8325 132.422 88.6968" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M168.638 138.077C167.553 139.709 166.814 140.813 166.814 140.813" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M170.408 99.0479C170.408 99.0479 178.698 103.74 179.792 110.621C180.887 117.502 178.228 121.109 176.664 124.853C175.752 127.001 172.665 131.921 170.18 135.733" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M105.684 125.701C105.684 125.701 108.164 128.177 113.737 127.557C113.737 127.557 108.164 130.033 105.684 125.701Z" fill="#333333" />
        <path d="M55.6055 123.699C55.6055 123.699 70.1199 116.089 96.6135 120.754C97.8317 120.967 99.0849 120.689 100.099 119.981C101.112 119.272 101.804 118.191 102.022 116.973L102.405 114.835C102.589 113.808 102.424 112.75 101.938 111.828C101.451 110.906 100.67 110.173 99.7189 109.746C92.6828 106.586 76.3124 97.8213 67.7077 81.5786L55.6055 123.699Z" fill="#5AC994" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M68.5684 90.3975C68.5684 90.3975 75.6546 101.515 87.4194 105.541" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M99.7181 109.746C95.6016 107.883 91.6493 105.678 87.9031 103.152C89.1389 107.101 89.4946 113.07 84.3828 119.358C88.4845 119.568 92.5691 120.034 96.6127 120.753C97.8309 120.967 99.0841 120.689 100.098 119.981C101.111 119.272 101.803 118.191 102.021 116.973L102.404 114.835C102.588 113.808 102.424 112.75 101.937 111.828C101.45 110.906 100.669 110.173 99.7181 109.746Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M67.2318 104.408C71.3647 91.1881 70.5841 79.1795 65.4882 77.5864C60.3924 75.9934 52.911 85.4192 48.7782 98.6396C44.6453 111.86 45.4259 123.869 50.5218 125.462C55.6176 127.055 63.099 117.629 67.2318 104.408Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M65.1729 103.767C68.891 91.8737 68.4424 81.1496 64.171 79.8143C59.8997 78.479 53.4229 87.0381 49.7048 98.9317C45.9868 110.825 46.4353 121.549 50.7067 122.885C54.978 124.22 61.4548 115.661 65.1729 103.767Z" fill="white" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M66.8898 96.9593L62.649 96.7358L59.457 107.598L62.8269 110.028C63.7187 107.989 64.4981 105.901 65.1616 103.776C65.8676 101.539 66.4446 99.2629 66.8898 96.9593Z" fill="#333333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M62.8908 102.7C62.0153 105.701 60.4877 107.894 59.4754 107.598C58.463 107.301 58.3582 104.634 59.2337 101.633C60.1092 98.6328 61.6414 96.4394 62.6491 96.7358C63.6569 97.0322 63.7663 99.7044 62.8908 102.7Z" fill="white" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M111.401 157.019C109.044 155.92 89.7094 121.497 88.6013 119.627C87.4932 117.758 87.817 115.067 88.916 112.555C90.0149 110.042 90.0149 105.323 89.372 104.379C88.729 103.435 86.8047 102.473 86.8047 102.473C86.8047 102.473 87.174 102.017 87.9584 101.082C88.7427 100.147 90.6305 100.767 90.6305 100.767C92.0441 99.6636 93.3072 101.46 93.3072 101.46C94.8805 100.516 95.6602 102.336 95.6602 102.336C96.2124 102.367 96.7504 102.523 97.2334 102.792C98.5421 103.64 98.3324 107.352 98.3324 107.352C98.3324 107.352 99.746 107.666 100.061 108.61C100.413 110.128 100.303 111.716 99.746 113.17C99.1167 114.744 95.186 119.459 95.186 119.459L114.675 152.938" fill="white" />
        <path d="M111.401 157.019C109.044 155.92 89.7094 121.497 88.6013 119.627C87.4932 117.758 87.817 115.067 88.916 112.555C90.0149 110.042 90.0149 105.323 89.372 104.379C88.729 103.435 86.8047 102.473 86.8047 102.473C86.8047 102.473 87.174 102.017 87.9584 101.082C88.7427 100.147 90.6305 100.767 90.6305 100.767C92.0441 99.6636 93.3072 101.46 93.3072 101.46C94.8805 100.516 95.6602 102.336 95.6602 102.336C96.2124 102.367 96.7504 102.522 97.2334 102.792C98.5421 103.64 98.3324 107.352 98.3324 107.352C98.3324 107.352 99.746 107.666 100.061 108.61C100.413 110.128 100.303 111.716 99.746 113.17C99.1167 114.744 95.186 119.459 95.186 119.459L114.675 152.938" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M90.6523 100.776C90.6523 100.776 92.9095 102.231 92.9095 103.512C92.9338 104.501 93.0176 105.488 93.1603 106.467" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M93.3301 101.446C93.3301 101.446 94.6981 102.883 94.7665 103.726C94.8349 104.57 95.0218 107.187 95.0218 107.187" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M95.6816 102.322C96.1511 103.013 96.418 103.822 96.4523 104.656C96.4617 105.531 96.518 106.405 96.621 107.274" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M98.3555 107.352C98.3555 107.352 98.9893 111.41 98.734 112.258" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </Box>
  );
}