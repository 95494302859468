import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Stack, Alert, Paper, Dialog, Typography, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// utils
import axiosInstance from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import MailboxIllustration from 'src/assets/illustration_mailbox';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function ConfirmEmailForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [showDialog, setShowDialog] = useState(!user.email_confirmed);

  const errTranslit = {
    user_not_found: "Пользователь не найден",
    already_confirmed: "Email уже подтвержден",
    message_not_sent: "Код не отправлен",
    invalid_code: "Неверный код",
    code_expired: "Срок действия кода истек"
  }

  const ConfirmEmailSchema = Yup.object().shape({
    code: Yup.string().required("Введите код").length(4, "Код состоит из 4-х символов")
  });

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    isInitialValid: false,
    validationSchema: ConfirmEmailSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await axiosInstance.post("/api/v1/user-management/email/confirm", {
          email: user.email,
          email_code: String(values.code),
        })
        enqueueSnackbar('Email подтвержден', { variant: 'success' });
        user.email_confirmed = true
        setShowDialog(false);
        setSubmitting(false);
      } catch (e) {
        setErrors({ afterSubmit: errTranslit[e.response?.data?.error] ?? 'Неизвестная ошибка' });
        setSubmitting(false);
      }
    }
  });

  const { isValid, errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={showDialog} disableEscapeKeyDown>
      <Paper sx={{ py: 3, px: 6 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <MailboxIllustration style={{ justifyContent: "center", display: "flex" }} />
            <Stack spacing={2}>
              <Typography textAlign="center" variant="h4">
                Подтверждение почты
              </Typography>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <Typography textAlign="center">
                Мы отправили письмо на <br />почту с 4-х значным кодом,<br /> который нужно ввести ниже
              </Typography>

              <Typography textAlign="center">
                После этого сможете использовать <br /> все функции платформы
              </Typography>

              <TextField
                fullWidth
                label="Код из почты"
                {...getFieldProps('code')}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 4)
                }}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code} />

              <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid} loading={isSubmitting} sx={{ mt: 3 }}>
                Подтвердить
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </Dialog>
  );
}
