import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useState } from "react";
import useAuth from '../../hooks/useAuth';

export default function SignOut() {
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>Выйти</Button>
      <Dialog onClose={handleClose} open={open} maxWidth="xs">
        <DialogTitle>Вы уверены?</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2">
            Вы действительно хотите выйти?
            <br />
            Отменить это действие будет невозможно
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Отменить
          </Button>
          <Button variant="contained" onClick={logout}>
            Выйти
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}