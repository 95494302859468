import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  error: false,
  bets: [],
};

const slice = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBetsSuccess(state, action) {
      state.isLoading = false;
      state.bets = action.payload;
    }
  }
})

export default slice.reducer;

export function getBets(dateFrom, dateTo, status, side, amount, offset) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      var params = new URLSearchParams();

      if (dateFrom) {
        params.append("created_at", `gte||${dateFrom.toISOString()}`)
      }
      if (dateTo) {
        params.append("created_at", `lte||${dateTo.toISOString()}`)
      }
      if (status && status !== "none") {
        params.append("status", status)
      }
      if (side && side !== "none") {
        params.append("side", side)
      }
      if (amount) {
        params.append("amount_btc", amount)
      }
      if (offset) {
        params.append("offset", offset)
      }

      params.append("order_by", "created_at.desc")

      const response = await axiosInstance.get("/api/v1/game/bets", {
        params: params,
      })

      dispatch(slice.actions.getBetsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}