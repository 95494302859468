import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import productReducer from './slices/product';
import txsReducer from './slices/txs';
import marketsReducer from './slices/markets';
import roundsReducer from './slices/rounds';
import betsReducer from './slices/bets';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  user: userReducer,
  product: persistReducer(productPersistConfig, productReducer),
  txs: txsReducer,
  markets: marketsReducer,
  rounds: roundsReducer,
  bets: betsReducer,
});

export { rootPersistConfig, rootReducer };
