import { replace } from 'lodash';
import numeral from 'numeral';
import { ru } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00', {
    locale: ru,
  });
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b', {
    locale: ru,
  });
}

export function fBTCFull(number) {
  return numeral(number).format('0.00000000')
}

export function fBTCShort(number) {
  return numeral(number).format('0.0[0000000]')
}

export function fRUB(number) {
  return numeral(number).format('0,0.[00]')
}