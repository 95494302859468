import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const access_token = window.localStorage.getItem('access_token');

        if (access_token && isValidToken(access_token)) {
          setSession(access_token);

          const response = await axios.get('/api/v1/user-management/my');
          const user = response.data;

          const balanceResponse = await axios.get('/api/v1/balance-management/balance');

          user.balance_btc = balanceResponse.data.balance_btc;
          user.frozen_btc = balanceResponse.data.frozen_btc;
          user.free_btc = balanceResponse.data.free_btc;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, otp) => {
    const response = await axios.post('/api/v1/user-management/login', {
      email,
      password,
      otp_code: otp,
    });
    const { access_token, user } = response.data;

    setSession(access_token);

    const balanceResponse = await axios.get('/api/v1/balance-management/balance');

    user.balance_btc = balanceResponse.data.balance_btc;
    user.frozen_btc = balanceResponse.data.frozen_btc;
    user.free_btc = balanceResponse.data.free_btc;

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (username, email, password) => {
    const response = await axios.post('/api/v1/user-management/register', {
      username,
      email,
      password
    });
    const { access_token, user } = response.data;

    window.localStorage.setItem('access_token', access_token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    await axios.post('/api/v1/user-management/pass/reset', {
      email
    });
  };

  const resetPasswordEnd = async (email, code, password, otp) => {
    await axios.post('/api/v1/user-management/pass/update', {
      email,
      code,
      new_password: password,
      otp_code: otp,
    });
  };

  const checkCode = async (email, code) => {
    await axios.get(`/api/v1/user-management/pass/check_code?email=${email}&code=${code}`);
  }

  const updateProfile = () => { };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        resetPasswordEnd,
        checkCode,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
