import { NavLink as RouterLink, useLocation, useSearchParams, useNavigate } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  Stack,
} from "@material-ui/core";
// hooks
import useAuth from "../../hooks/useAuth";
import useOffSetTop from "../../hooks/useOffSetTop";
// components
import Logo from "../../components/Logo";
import Label from "../../components/Label";
import { MHidden } from "../../components/@material-extend";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { PATH_DASHBOARD } from "src/routes/paths";
import { LoginForm } from "src/components/authentication/login";
import { RegisterForm } from "src/components/authentication/register";
import { useState } from "react";
import SignOut from "src/components/authentication/SignOut";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  const isHome = pathname === "/";
  const [searchParams, setSearchParams] = useSearchParams()

  const next = searchParams.get("next")

  const [loginOpen, setLoginOpen] = useState(Boolean(next));
  const [registerOpen, setRegisterOpen] = useState(false);

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Label color="info" sx={{ ml: 1, mr: 3 }}>
            v2.1.0
          </Label>

          <MHidden width="mdDown">
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            {!isAuthenticated && <Stack direction="row" spacing={2}>
              {/* <Button
                component={RouterLink}
                to={PATH_DASHBOARD.general.app}
                variant="outlined"
              >
                Демо
              </Button> */}
              <RegisterForm
                onClose={() => { setRegisterOpen(false); }}
                onOpen={() => { setRegisterOpen(true); }}
                open={registerOpen}
                onOpenLogin={() => {
                  setRegisterOpen(false);
                  setLoginOpen(true);
                }} />
              <LoginForm
                onClose={() => { setLoginOpen(false); }}
                onOpen={() => { setLoginOpen(true); }}
                open={loginOpen}
                onSuccess={() => {
                  if (next) {
                    navigate(next)
                  } else {
                    navigate(PATH_DASHBOARD.root);
                  }
                }}
                onOpenRegister={() => {
                  setLoginOpen(false);
                  setRegisterOpen(true);
                }} />
            </Stack>}

            {isAuthenticated && <Stack direction="row" spacing={2}>
              <SignOut />
              <Button
                component={RouterLink}
                to={PATH_DASHBOARD.general.app}
                variant="contained"
              >
                В панель управления
              </Button>
            </Stack>}
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
