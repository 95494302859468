import PropTypes from 'prop-types';
import { ConfirmEmailForm } from 'src/components/authentication/verify-code';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

EmailGuard.propTypes = {
  children: PropTypes.node
};

export default function EmailGuard({ children }) {
  const { user } = useAuth();

  if (user !== null && !user.email_confirmed) {
    return <>
      <ConfirmEmailForm />
      {children}
    </>;
  }

  return <>{children}</>;
}
