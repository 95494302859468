import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack, Dialog, Button, Paper, Typography, InputAdornment, IconButton, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { MIconButton } from '../../@material-extend';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// hooks
import useAuth from '../../../hooks/useAuth';
import { useState } from 'react';
import { MailSentIllustration } from 'src/assets';
import { useSnackbar } from 'notistack';

export default function ResetPasswordForm() {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState("request");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const handleTapOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <Button variant="text" sx={{ m: 0 }} onClick={handleTapOpen}>
        Забыли пароль?
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        {step === "request" && <RequestForm
          onSent={(email) => {
            setStep("wait");
            setEmail(email);
          }}
        />}
        {step === "wait" && <WaitForm
          email={email}
          onConfirm={(code) => {
            setStep("change");
            setCode(code);
          }}
        />}
        {step === "change" && <ChangeForm
          email={email}
          code={code}
          onReset={() => {
            handleClose();
            setStep("request");
            setEmail("");
            setCode("");
          }}
        />}
      </Dialog>
    </>
  );
}

function RequestForm({ onSent }) {
  const { resetPassword } = useAuth();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Неверный формат почты').required('Введите email')
  });

  const errTranslit = {
    user_not_found: "Пользователь не найден",
    email_not_confirmed: "Email еще не подтвержден",
    message_sent: "Сообщение уже отправлено",
  }

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    isInitialValid: false,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await resetPassword(values.email);
        setSubmitting(false);
        onSent(values.email);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        if (error.response?.data?.error === "message_sent") {
          onSent(values.email);
        } else {
          setErrors({ afterSubmit: errTranslit[error.response?.data?.error] ?? "Неизвестная ошибка" });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>
          Забыли пароль?
        </DialogTitle>
        {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              {...getFieldProps('email')}
              type="email"
              label="Email адрес"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <Typography variant="subtitle2">
              Введите свой Email на который <br />зарегистрирован профиль
            </Typography>

          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!formik.isValid}>
            Восстановить пароль
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

function WaitForm({ email, onConfirm }) {
  const { checkCode } = useAuth();

  const CheckCodeSchema = Yup.object().shape({
    code: Yup.string().length(6, "Код состоит из 6 символов").required('Введите код')
  });

  const errTranslit = {
    user_not_found: "Пользователь не найден",
    request_not_sent: "Запрос не отправлен",
    request_expired: "Срок действия запроса истек",
    invalid_code: "Неверный код"
  }

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: CheckCodeSchema,
    isInitialValid: false,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await checkCode(email, String(values.code));
        setSubmitting(false);
        onConfirm(String(values.code));
      } catch (error) {
        console.error(error);
        setErrors({ afterSubmit: errTranslit[error.response?.data?.error] ?? "Неизвестная ошибка" });
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, isValid, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <MailSentIllustration style={{ justifyContent: "center", display: "flex" }} />
        <DialogTitle>
          Мы отправили вам код на<br /> {email}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant='subtitle2'>
              В письме указан 6-ти значный<br /> код, который нужно ввести ниже,<br /> чтобы восстановить пароль
            </Typography>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

            <TextField
              fullWidth
              {...getFieldProps("code")}
              type="number"
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 6)
              }}
              label="Код подтверждения"
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton fullWidth size="large" disabled={!isValid} type="submit" variant="contained" loading={isSubmitting}>
            Восстановить пароль
          </LoadingButton>
        </DialogActions>

      </Form>
    </FormikProvider>
  );
}

function ChangeForm({ email, code, onReset }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { resetPasswordEnd } = useAuth();

  const ChangeSchema = Yup.object().shape({
    password: Yup.string().required("Введите пароль").min(5, 'Слишком короткий'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Пароли должны совпадать").required("Введите пароль").min(5, 'Слишком короткий'),
    otp: Yup.string(),
  });


  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
      otp: "",
    },
    isInitialValid: false,
    validationSchema: ChangeSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await resetPasswordEnd(email, String(code), values.password, String(values.otp));
        enqueueSnackbar('Пароль успешно изменен', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        onReset()
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        if (error.response?.data?.error === "empty_otp") {
          setShowOTP(true);
        } else {
          // setErrors({ afterSubmit: errTranslit[error.response?.data?.error] ?? "Неизвестная ошибка" });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, isValid, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Paper sx={{ p: 4, px: 6 }}>
          <Stack spacing={3}>
            <Typography textAlign="center" variant='h4'>
              Новый пароль
            </Typography>

            <TextField
              fullWidth
              {...getFieldProps("password")}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Новый пароль"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              fullWidth
              {...getFieldProps("passwordConfirm")}
              autoComplete="current-password"
              type={showPasswordConfirmation ? 'text' : 'password'}
              label="Подтвердить пароль"
              error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
              helperText={touched.passwordConfirm && errors.passwordConfirm}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)} edge="end">
                      <Icon icon={showPasswordConfirmation ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {showOTP && <TextField
              fullWidth
              {...getFieldProps("otp")}
              type="number"
              label="Код Google authenticator"
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />}

            <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid} loading={isSubmitting}>
              Сменить пароль
            </LoadingButton>
          </Stack>
        </Paper>
      </Form>
    </FormikProvider>
  )
}