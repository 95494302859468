import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
//
import { MIconButton } from '../../@material-extend';
import { ResetPasswordForm } from '../reset-password';

// ----------------------------------------------------------------------

export default function LoginForm({ onClose, onOpen, open, onOpenRegister, onSuccess }) {
  const { login } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Неверный адрес').required('Введите email'),
    password: Yup.string().required('Введите пароль'),
    otp: Yup.string(),
  });

  const errTranslit = {
    user_not_found: "Пользователь не найден",
    invalid_password: "Неверный логин или пароль",
    invalid_otp: "Неверный код Google authenticator",
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      otp: '',
    },
    isInitialValid: false,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password, String(values.otp));
        enqueueSnackbar('Авторизация успешна', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        setSubmitting(false);
        if (onSuccess !== null) {
          onSuccess();
        }
      } catch (error) {
        setSubmitting(false);
        if (error.response?.data?.error === "empty_otp") {
          setShowOTP(true);
        } else {
          enqueueSnackbar(errTranslit[error.response?.data?.error] ?? 'Неизвестная ошибка', { variant: 'error' })
        }
      }
    }
  });

  const { errors, touched, isSubmitting, isValid, handleSubmit, getFieldProps, resetForm } = formik;

  const handleTapOpen = () => {
    onOpen();
  }

  const handleClose = () => {
    onClose();

    if (showOTP) {
      setShowPassword(false);
      setShowOTP(false);
      resetForm();
    }
  }

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleTapOpen}>Войти</Button>
      <Dialog onClose={handleClose} open={open} maxWidth="xs">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>Вход</DialogTitle>

            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email или имя пользователя"
                  {...getFieldProps('email')}
                  disabled={showOTP}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Пароль"
                  {...getFieldProps('password')}
                  disabled={showOTP}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                {showOTP && <TextField
                  fullWidth
                  label="Код Google authenticator"
                  {...getFieldProps('otp')}
                  type="number"
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 6)
                  }}
                  error={Boolean(touched.otp && errors.otp)}
                  helperText={touched.otp && errors.otp} />}
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="start" sx={{ my: 0.5 }}>
                <ResetPasswordForm />
              </Stack>

              <Stack spacing={1} justifyContent="center" alignItems="center">
                <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid} loading={isSubmitting}>
                  Войти
                </LoadingButton>

                {!showOTP && <Typography variant="subtitle2" sx={{ pt: 2 }}>
                  Еще нет профиля?
                </Typography>}

                {!showOTP && <Button fullWidth size="large" variant="outlined" onClick={onOpenRegister}>
                  Зарегистрироваться
                </Button>}
              </Stack>
            </DialogContent>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
}
