import { Box } from "@material-ui/core";

export default function MailSentIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg
        width={228}
        height={180}
        viewBox="10 20 228 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={0.7}>
          <path
            d="M170.498 162.218s13.985-3.648 24.929-21.888c12.267-20.447 7.004-56.125-9.334-73.457-15.158-16.074-48.715-18.902-68.186-11.058-18.4 7.414-32.53 25.235-43.776 27.665-11.245 2.43-25.235-1.518-40.433 15.199-15.199 16.717-11.856 54.419 5.472 66.881 17.328 12.463 42.864-2.129 65.057-12.161 22.194-10.032 25.837 11.856 66.271 8.819Z"
            fill="#333"
          />
          <path
            opacity={0.7}
            d="M170.498 162.218s13.985-3.648 24.929-21.888c12.267-20.447 7.004-56.125-9.334-73.457-15.158-16.074-48.715-18.902-68.186-11.058-18.4 7.414-32.53 25.235-43.776 27.665-11.245 2.43-25.235-1.518-40.433 15.199-15.199 16.717-11.856 54.419 5.472 66.881 17.328 12.463 42.864-2.129 65.057-12.161 22.194-10.032 25.837 11.856 66.271 8.819Z"
            fill="#000"
          />
        </g>
        <path
          d="M165.865 125.4v-.027c-3.721-1.236-9.43-.817-10.278-.31a8.289 8.289 0 0 0-2.28 2.28 27.048 27.048 0 0 0-4.232 9.434l-40.47 3.762c-1.491-3.219-3.739-6.106-5.996-7.848a6.526 6.526 0 0 0-2.189-1.208 3.446 3.446 0 0 0-1.058.037c-1.468-4.255-3.548-8.359-6.147-8.619-5.472-.547-15.02 1.286-17.624 6.594-2.604 5.308-3.42 18.559-3.42 18.559l-1.824 1.824a21.194 21.194 0 0 0-5.436-6.726 6.375 6.375 0 0 0-2.189-1.208c-.456-.128-2.28.155-4.19.779-.078-.073-.15-.146-.233-.214-1.824-1.528-5.563-2.362-7.651 1.532-2.089 3.894.42 11.268 1.669 12.937a8.388 8.388 0 0 0 2.243 2.157c1.473 3.648 4.104 8.299 8.153 8.513a9.688 9.688 0 0 0 7.492-2.945c2.627-2.736 2.91-6.58 1.92-10.342l.424-.621s.944 1.346 4.56.803a5.4 5.4 0 0 0 2.86-1.186c1.696 4.328 4.436 8.664 8.791 9.312a13.16 13.16 0 0 0 10.538-3.001 11.657 11.657 0 0 0 2.166-2.485 9.663 9.663 0 0 0 6.43-2.946 9.006 9.006 0 0 0 2.435-5.95l5.385-.63a23.57 23.57 0 0 0 10.766 2.016 15.302 15.302 0 0 0 9.481-4.104l12.271-1.049c.205 4.756 1.915 9.12 5.928 11.523a12.382 12.382 0 0 0 10.305 1.099c5.659-1.897 7-10.305 7.365-15.417.565-8.208-2.052-15.162-5.965-16.325Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.945 160.029a5.093 5.093 0 0 1-1.755 1.427M93.157 149.221s.543 5.928-1.477 9.69M94.756 155.77c-.515 2.28-1.541 4.491-3.53 5.472M97.232 148.77s.912 9.854-3.963 12.235"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.276 148.542s.912 9.859-3.967 12.234M160.794 155.45a6.32 6.32 0 0 1-1.915 1.619M164.461 143.143s-.292 6.744-2.795 11.035M165.113 150.612c-.821 2.595-2.134 5.112-4.209 6.206M168.511 142.641s-.488 11.236-5.581 13.927"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.544 142.395s-.488 11.232-5.581 13.927M64 166.125a4.936 4.936 0 0 1-1.5 1.478M64.72 156.536s1.287 5.016-.163 8.522M67.105 161.921c-.206 2.029-.913 4.072-2.677 5.194M68.57 155.56s2.143 8.322-2.216 11.062"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.493 155.067s2.148 8.322-2.216 11.067"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.21 145.843c1.086 6.206.406 11.514-1.509 11.856-1.915.342-4.355-4.428-5.44-10.634s-.406-11.514 1.514-11.856c1.92-.342 4.35 4.423 5.435 10.634Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.245 145.975c.301 2.38.023 4.373-.629 4.455-.652.082-1.418-1.779-1.72-4.159-.3-2.38 0-4.377.63-4.46.63-.082 1.418 1.783 1.72 4.164Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.672 140.284c.065-.799.191-1.592.378-2.371M83.695 155.46a30.323 30.323 0 0 1-2.681-7.994 28.874 28.874 0 0 1-.415-5.951"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.376 164.287c1.378-.382 1.493-4.31.256-8.773s-3.357-7.772-4.736-7.39c-1.378.382-1.493 4.31-.256 8.773 1.237 4.464 3.357 7.772 4.736 7.39Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.179 155.692c.433 1.697.41 3.165-.055 3.283-.465.119-1.185-1.162-1.619-2.854-.433-1.692-.41-3.169.055-3.283.465-.114 1.186 1.158 1.619 2.854ZM55.358 151.985c-.025-.584-.005-1.17.06-1.751M58.912 162.696a22.077 22.077 0 0 1-2.659-5.545 21.074 21.074 0 0 1-.839-4.268"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.123 79.344c-4.806-9.918-6.881-10.78-10.077-10.944-3.197-.164-10.744-1.104-19.152.242 0 0-23.457-.41-41.606 10.584-18.947 11.477-28.167 21.559-29.457 26.785-1.291 5.226-5.696 18.842-7.784 26.649-2.089 7.806-3.512 14.683-.849 21.003 1.61 3.821 3.325 4.97 5.527 4.952a15.918 15.918 0 0 1-.889-1.874c-2.189-5.422-1.924-13.178.575-13.68 2.498-.502 3.99 4.692 5.499 10.356.3 1.248.705 2.468 1.213 3.648 3.735-1.409 9.717-4.56 17.67-7.834a51.15 51.15 0 0 1-.05-15.012c1.176-7.784 6.151-9.503 8.317-4.765 1.792 3.903 2.458 12.64 2.636 15.586 3.192-1.099 5.864-1.824 7.51-1.924 0 0 27.214-6.147 47.274-6.64 20.059-.492 30.096-.971 30.096-.971s-11.647-46.261-16.453-56.16Z"
          fill="#fff"
        />
        <path
          d="M92.992 127.197s-2.759 3.775-6.115 4.583c1.186 4.35 1.674 10.789 1.824 13.224 2.85-.981 5.271-1.669 6.931-1.87.242-1.423.388-2.33.388-2.33l-3.028-13.607Z"
          fill="#5AC994"
        />
        <path
          opacity={0.2}
          d="M59.028 153.827c1.268.128 2.111.675 4.688.032 4.56-1.135 1.281-9.936 8.846-13.529a12.731 12.731 0 0 1 4.706-1.323c.068-1.61.22-3.216.456-4.811.2-1.566.663-3.087 1.368-4.5-2.066-.634-2.44-1.241-5.96.433-7.57 3.593-6.434 12.864-10.971 13.999-3.62.912-3.822-.542-6.571.132 1.286 1.824 2.28 5.377 3.283 9.12.078.169.114.292.155.447Z"
          fill="#5AC994"
        />
        <path
          d="M51.583 146.034c-2.12 1.459-3.384 3.648-4.105 5.495.196.729.437 1.444.721 2.143 1.61 3.821 3.324 4.971 5.527 4.952a15.769 15.769 0 0 1-.89-1.874c-1.39-3.452-1.764-7.843-1.253-10.716ZM147.046 68.4l-.825-.05c-33.256 8.51-46.786 48.591-51.442 67.908.251 1.919.525 3.803.821 5.636.566-.11.173 1.222.607 1.199 0 0 27.214-6.147 47.273-6.639 20.06-.493 30.096-.972 30.096-.972s-11.646-46.238-16.452-56.138c-4.807-9.9-6.881-10.798-10.078-10.944Z"
          fill="#5AC994"
          style={{
            mixBlendMode: "multiply",
          }}
          opacity={0.2}
        />
        <path
          d="M157.123 79.344c-4.806-9.918-6.881-10.78-10.077-10.944-3.197-.164-10.744-1.104-19.152.242 0 0-23.457-.41-41.606 10.584-18.947 11.477-28.167 21.559-29.457 26.785-1.291 5.226-5.696 18.842-7.784 26.649-2.089 7.806-3.512 14.683-.849 21.003 1.61 3.821 3.325 4.97 5.527 4.952a15.918 15.918 0 0 1-.889-1.874c-2.189-5.422-1.924-13.178.575-13.68 2.498-.502 3.99 4.692 5.499 10.356.3 1.248.705 2.468 1.213 3.648 3.735-1.409 9.717-4.56 17.67-7.834a51.15 51.15 0 0 1-.05-15.012c1.176-7.784 6.151-9.503 8.317-4.765 1.792 3.903 2.458 12.64 2.636 15.586 3.192-1.099 5.864-1.824 7.51-1.924 0 0 27.214-6.147 47.274-6.64 20.059-.492 30.096-.971 30.096-.971s-11.647-46.261-16.453-56.16Z"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity={0.2}
          d="M57.58 129.395a2.13 2.13 0 0 0 2.28.287c1.437-.575 1.582-2.736 1.582-2.736l2.153-.141.433-6.754-4.743 1.865s-1.272 6.617-1.705 7.479Z"
          fill="#5AC994"
        />
        <path
          d="M51.36 149.144a23.624 23.624 0 0 0-3.844 2.554c.189.668.417 1.324.684 1.965 1.61 3.821 3.324 4.97 5.526 4.952a15.859 15.859 0 0 1-.889-1.874 22.137 22.137 0 0 1-1.477-7.597Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.624 145.377a.686.686 0 0 1-.602-.36.679.679 0 0 1 .273-.912c.11-.059 8.983-5.559 16.334-9.466a.682.682 0 0 1 .85 1.039.686.686 0 0 1-.207.169c-7.346 3.903-16.215 9.398-16.325 9.457a.65.65 0 0 1-.323.073Z"
          fill="#333"
          stroke="#333"
          strokeMiterlimit={10}
        />
        <path
          d="m70.224 99.668-.315 7.829c-.068 1.067.552 1.583 1.368 1.122l12.609-8.208a3.332 3.332 0 0 0 1.423-2.9l-.078-8.732c-.091-.794-.63-1.117-1.29-.775-2.372 1.226-6.95 5.376-12.422 9.238a3.449 3.449 0 0 0-1.295 2.426Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m78.742 97.46 6.16 1.506M70.314 108.656l3.804-8.4M70.297 99.408l5.59.698c.58.086 1.3-.393 1.824-1.222l7.296-10.448"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m101.51 85.122 1.295 19.608a4.173 4.173 0 0 0 4.637 3.898L151 102.974a4.168 4.168 0 0 0 3.642-3.488 4.17 4.17 0 0 0-.104-1.788l-5.499-19.444a4.167 4.167 0 0 0-4.314-3.023c-7.059.52-23.99 2.038-39.973 5.6a4.174 4.174 0 0 0-3.242 4.29Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m133.887 91.2 19.886 9.421M104.455 107.767l16.795-15.03M101.656 84.606l24.1 9.91a5.046 5.046 0 0 0 5.408-1.036l17.178-16.22"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.785 105.749c4.093-.101 7.332-3.444 7.233-7.467-.099-4.023-3.498-7.203-7.592-7.102-4.094.1-7.332 3.444-7.233 7.467.099 4.023 3.498 7.203 7.592 7.102Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.806 101.943h-.023a.907.907 0 0 1-.738-.374l-2.914-4.022a.914.914 0 1 1 1.477-1.071l2.175 3 5.199-7.182a.912.912 0 1 1 1.477 1.072l-5.928 8.208a.914.914 0 0 1-.725.369Z"
          fill="#fff"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M121.264 121.597s15.504-2.973 26.128-2.763l.639 2.553s-22.308 1.058-26.558 3.192l-.209-2.982Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m121.475 124.57.456 2.554a1.104 1.104 0 0 0 1.244.912c3.89-.556 17.543-2.431 24.624-2.257a1.117 1.117 0 0 0 .909-.428 1.1 1.1 0 0 0 .19-.986l-.848-2.969"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m64.898 120.06-.087-10.533.524-10.83a.516.516 0 0 0-.88-.383c-1.96 2.006-5.317 5.043-6.31 7.533-1.291 3.224-2.819 19.093-2.819 19.093l9.572-4.88ZM52.755 117.944h-1.528a1.41 1.41 0 0 0-1.409 1.409v7.871c0 .778.631 1.409 1.41 1.409h1.527a1.41 1.41 0 0 0 1.409-1.409v-7.871a1.41 1.41 0 0 0-1.409-1.409Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.717 110.594c.723.138 1.43.354 2.107.643a5.497 5.497 0 0 1 1.509 1.094l2.28-3.014s-3.343-1.4-3.976-2.253l-.63-.857-1.29 4.387Z"
          fill="#fff"
        />
        <path
          d="m55.632 107.064-.588-.816-.069.046-1.258 4.286s.242.046.584.128l3.26-2.362a6.697 6.697 0 0 1-1.929-1.282Z"
          fill="#5AC994"
          style={{
            mixBlendMode: "multiply",
          }}
          opacity={0.5}
        />
        <path
          d="M53.717 110.594c.723.138 1.43.354 2.107.643a5.497 5.497 0 0 1 1.509 1.094l2.28-3.014s-3.343-1.4-3.976-2.253l-.63-.857-1.29 4.387Z"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m58.532 108.036-2.786 3.552.379 1.208 4.245-4.409-1.838-.351Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.072 97.999c-1.883-1.46-6.79-.529-7.752 2.927-.593 2.13-.264 5.322-.264 5.322l5.59-1.186.456.689c.912 1.368 2.887.456 3.397 0 .278-.242.26-.953.187-1.541l.224-.05s.04-4.697-1.838-6.161Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.984 104.643a17.96 17.96 0 0 0-7.296.693c-3.648 1.222-4.209 2.28-1.596 2.216 2.613-.064 8.892-2.909 8.892-2.909Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.48 103.316c.425 0 .77-.471.77-1.053s-.345-1.054-.77-1.054c-.426 0-.771.472-.771 1.054 0 .582.345 1.053.77 1.053Z"
          fill="#5AC994"
        />
        <path
          d="M64.811 109.527s-4.993-1.268-6.53-.101c-1.536 1.168-1.929 3.097-1.436 5.436.492 2.339 1.692 8.491 1.692 8.491l6.36-3.293-.086-10.533Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.886 122.372s-.269 2.362-.583 2.695c-.315.333-8.614-6.639-9.12-7.296-.507-.657-.16-3 0-3.735.16-.734-.11-1.135-.912-.629-.803.506-.589 2.613-.589 2.613s-2.448-.588-2.936-.657c-.488-.068-.912.538-.84 1.022.074.483.717.524.717.524a3.65 3.65 0 0 0 .132 2.107c.428.885.912 1.441 1.824 1.66a15.14 15.14 0 0 0 2.845.173s4.241 4.04 5.472 5.55c1.231 1.509 3.826 3.561 5.117 3.192 1.29-.37 2.521-6.813 2.521-6.813l-3.648-.406Z"
          fill="#fff"
        />
        <path
          d="m56.301 125.067 2.426.807a2.156 2.156 0 0 1-1.486 2.381c-1.072.355-2.996-.862-4.364-1.888l.041.05c1.254 1.514 3.826 3.561 5.116 3.192 1.29-.369 2.522-6.813 2.522-6.813l-3.67-.456s-.27 2.394-.585 2.727Z"
          fill="#5AC994"
          style={{
            mixBlendMode: "multiply",
          }}
          opacity={0.5}
        />
        <path
          d="M56.886 122.372s-.269 2.362-.583 2.695c-.315.333-8.614-6.639-9.12-7.296-.507-.657-.16-3 0-3.735.16-.734-.11-1.135-.912-.629-.803.506-.589 2.613-.589 2.613s-2.448-.588-2.936-.657c-.488-.068-.912.538-.84 1.022.074.483.717.524.717.524a3.65 3.65 0 0 0 .132 2.107c.428.885.912 1.441 1.824 1.66a15.14 15.14 0 0 0 2.845.173s4.241 4.04 5.472 5.55c1.231 1.509 3.826 3.561 5.117 3.192 1.29-.37 2.521-6.813 2.521-6.813l-3.648-.406Z"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m48.591 119.052-1.167 1.815.597.406 1.195-1.71-.625-.511Z"
          fill="#333"
          stroke="#333"
          strokeMiterlimit={10}
        />
        <path
          d="M48.19 120.763a.457.457 0 1 0 0-.913.457.457 0 0 0 0 .913Z"
          fill="#fff"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.072 106.832s.278.688.743 1.897c.466 1.208 2.965 3.757 4.187 3.903 1.222.146 2.685-4.295 2.918-5.212.078-.301.155-.784.155-.784s1.537-.725 1.181-1.601c-.356-.875-1.076-1.277-1.418-1.008-.342.27-.401 1.405-.912 1.414-.51.009-.789-.15-1.24-.752-.452-.602-5.13.647-5.614 2.143Z"
          fill="#fff"
        />
        <path
          opacity={0.5}
          d="M52.676 104.688c-.456-.597-5.12.652-5.604 2.143l.265.675a20.345 20.345 0 0 1 5.745-2.357 2.885 2.885 0 0 1-.406-.461Z"
          fill="#5AC994"
        />
        <path
          d="M56.197 104.912c-.374-.785-1.035-1.14-1.368-.885a2.696 2.696 0 0 0-.456.912 9.421 9.421 0 0 1 1.824-.027Z"
          fill="#5AC994"
          style={{
            mixBlendMode: "multiply",
          }}
          opacity={0.5}
        />
        <path
          d="M47.072 106.832s.278.688.743 1.897c.466 1.208 2.965 3.757 4.187 3.903 1.222.146 2.685-4.295 2.918-5.212.078-.301.155-.784.155-.784s1.537-.725 1.181-1.601c-.356-.875-1.076-1.277-1.418-1.008-.342.27-.401 1.405-.912 1.414-.51.009-.789-.15-1.24-.752-.452-.602-5.13.647-5.614 2.143Z"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.729 107.16c.082.278.055.534-.055.566-.11.032-.269-.169-.35-.456-.083-.288-.056-.534.054-.566.11-.032.269.187.351.456Z"
          fill="#333"
          stroke="#333"
          strokeMiterlimit={10}
        />
        <path
          d="M47.88 106.385c.64-.182.913.296.99.739l.119.524.397 2.029.602-.255M50.478 106.54a1.31 1.31 0 0 1 1.108-.42M51.01 105.277a1.367 1.367 0 0 0-1.059 1.053M50.53 110.078s1.322-.264 1.427-1.213"
          stroke="#333"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m61.756 125.792-6.676-1.86s.588-11.4 1.619-13.329c1.605-2.996 5.285-1.646 5.978 2.12.648 3.557-.921 13.069-.921 13.069Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.24 122.664s3.648.839 5.869 1.569"
          stroke="#333"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.018 108.61s2.17.169 3.588 1.336"
          stroke="#5AC994"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.267 137.771s-9.722 3.43-10.611 4.364c-.89.935-.798 4.583 1.3 6.357 2.097 1.774 17.049-2.248 27.738-3.42 10.688-1.172 34.154-2.896 43.73-3.361 9.576-.465 13.931.798 14.77-2.189.839-2.986.734-6.178-1.09-6.826-1.824-.647-35.057-5.969-75.837 5.075Z"
          fill="#333"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.963 144.128s.456 3.954 2.886 4.56c2.43.607 31.314-4.865 48.336-5.472 17.023-.606 31.92-1.062 31.92-1.062"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.07 146.134c11.291-2.736 34.515-6.603 73.594-5.504M92.814 148.542s2.595-.629 6.54-1.705M95.195 148.993a25.692 25.692 0 0 1-.136 5.085"
          stroke="#fff"
          strokeWidth={0.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.78 76.075s-2.176 2.549-2.344 4.833M92.208 82.75s-1.637 26.043 2.695 53.758"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.723 112.842s-3.534 1.231-3.58 2.827c-.118 4.291.334 13.447 1.268 14.337.935.889 3.84-1.464 3.84-1.464s4.104.356 4.56-.566c.456-.921-1.254-13.885-1.51-15.016-.255-1.131-4.578-.118-4.578-.118ZM166.541 119.016a7.76 7.76 0 0 0 2.941.775 1131.07 1131.07 0 0 0-3.734-13.707c-1.195-.082-2.736-.082-2.705.456.023.921 2.344 11.81 3.498 12.476Z"
          fill="#5AC994"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.217 114.182s.155 8.208.761 11.4"
          stroke="#333"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.844 116.08s20.346-3.279 44.054.912M196.787 128.934c1.4.42 2.777.862 4.104 1.336M160.256 122.742s16.133.98 31.582 4.842M111.154 160.311a150.418 150.418 0 0 1 40.128-.715M109.486 158.164s9.549-1.195 12.654-1.432M173.436 69.6c1.368.195 2.736.455 3.976.752M153.777 69.148c5.619-.397 11.26-.359 16.872.114M80.205 124.388s13.625-6.453 33.338-8.245M103.152 112.555s8.249-2.148 20.799-2.869M79.982 158.114a14.086 14.086 0 0 0 7.524 6.384M79.527 160.166s2.052 3.192 4.788 4.56M146.787 148.765s1.368 6.384 6.84 9.12M147.471 154.466a10.548 10.548 0 0 0 4.332 3.648M57.256 166.759s1.368 2.28 6.156 2.28M56.344 168.127a10.998 10.998 0 0 0 6.156 2.508"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.986 162.5a193.034 193.034 0 0 0-35.906 5.372l.283.424s10.032-2.13 28.573 2.43c18.541 4.56 19.763 8.208 40.128 8.208s40.128-4.861 40.128-4.861l-2.472-1.368c-18.285-7.738-43.347-11.737-70.734-10.205Z"
          fill="#5AC994"
        />
        <path
          opacity={0.5}
          d="M117.986 162.5a193.034 193.034 0 0 0-35.906 5.372l.283.424s10.032-2.13 28.573 2.43c18.541 4.56 19.763 8.208 40.128 8.208s40.128-4.861 40.128-4.861l-2.472-1.368c-18.285-7.738-43.347-11.737-70.734-10.205Z"
          fill="#fff"
        />
        <path
          d="M73.371 172.933s23.94-3.648 44.688 10.26"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.451 178.177s9.804 2.964 15.732 9.348M156.363 166.549s13.908 1.368 26.448 8.892M199.729 178.333a57.755 57.755 0 0 1 7.131 5.148"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.576 189.089c16.621-14.136 46.654-24.624 81.41-26.589 31.724-1.801 60.338 3.881 78.888 14.136"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.895 166.321s14.364.684 23.94 3.876"
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.769 58.067s-.457-6.307-9.69-6.535c-9.235-.228-6.763 6.311-9.24 7.889-2.475 1.578-3.155-.912-5.635.675-2.48 1.587-1.368 4.733-3.38 5.185-2.01.451-4.732-2.03-7.209-.456-2.476 1.573-2.48 2.927-3.607 2.927-1.126 0-3.379-.912-4.728 0-1.35.912-2.932 2.016-2.932 2.016h57s.674-2.03-1.824-3.38c-2.5-1.35-3.607-.227-4.506-1.367-.898-1.14 2.508-4.022-4.25-6.954ZM106.636 33.93s-.274-3.793-5.828-3.93-4.068 3.798-5.559 4.747c-1.49.949-1.897-.543-3.388.406-1.49.948-.811 2.845-2.033 3.114-1.222.27-2.846-1.217-4.337-.269-1.491.949-1.491 1.76-2.17 1.76-.68 0-2.034-.538-2.846 0-.812.538-1.76 1.223-1.76 1.223h34.291s.406-1.223-1.085-2.034c-1.491-.812-2.166-.137-2.709-.812-.543-.675 1.491-2.44-2.576-4.204Z"
          fill="#5AC994"
        />
        <path
          opacity={0.7}
          d="M72.769 58.067s-.457-6.307-9.69-6.535c-9.235-.228-6.763 6.311-9.24 7.889-2.475 1.578-3.155-.912-5.635.675-2.48 1.587-1.368 4.733-3.38 5.185-2.01.451-4.732-2.03-7.209-.456-2.476 1.573-2.48 2.927-3.607 2.927-1.126 0-3.379-.912-4.728 0-1.35.912-2.932 2.016-2.932 2.016h57s.674-2.03-1.824-3.38c-2.5-1.35-3.607-.227-4.506-1.367-.898-1.14 2.508-4.022-4.25-6.954Z"
          fill="#fff"
        />
        <path
          opacity={0.8}
          d="M106.636 33.93s-.274-3.793-5.828-3.93-4.068 3.798-5.559 4.747c-1.49.949-1.897-.543-3.388.406-1.49.948-.811 2.845-2.033 3.114-1.222.27-2.846-1.217-4.337-.269-1.491.949-1.491 1.76-2.17 1.76-.68 0-2.034-.538-2.846 0-.812.538-1.76 1.223-1.76 1.223h34.291s.406-1.223-1.085-2.034c-1.491-.812-2.166-.137-2.709-.812-.543-.675 1.491-2.44-2.576-4.204Z"
          fill="#fff"
        />
      </svg>
    </Box>
  )
}