import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
  isLoading: false,
  error: false,
  txs: [],
}

const slice = createSlice({
  name: "txs",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTxsSuccess(state, action) {
      state.isLoading = false;
      state.txs = action.payload;
    }
  }
})

// ❤️❤️❤️-❤️❤️❤️-❤️❤️❤️-❤️❤️❤️
// у тебя все получится!
// ❤️❤️❤️-❤️❤️❤️-❤️❤️❤️-❤️❤️❤️

export default slice.reducer;

export function getTxs(dateFrom, dateTo, amount, txType, offset) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      var params = new URLSearchParams();

      if (dateFrom) {
        params.append("created_at", `gte||${dateFrom.toISOString()}`)
      }
      if (dateTo) {
        params.append("created_at", `lte||${dateTo.toISOString()}`)
      }
      if (amount) {
        params.append("amount_btc", amount)
      }
      if (offset) {
        params.append("offset", offset)
      }

      if (txType && txType !== "none") {
        params.append("amount_btc", txType)
      }

      params.append("order_by", "created_at.desc")

      const response = await axiosInstance.get("/api/v1/balance-management/transactions", {
        params: params,
      });

      dispatch(slice.actions.getTxsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}