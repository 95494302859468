import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, ListItem, ListItemText, ListItemIcon, Badge, Link } from '@material-ui/core';
import { PATH_DASHBOARD } from 'src/routes/paths';
import SvgIconStyle from './SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  list: getIcon("ic_list"),
  clock: getIcon("ic_clock"),
  question: getIcon("ic_question")
};

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  // textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.primary,
  fontWeight: "bold",
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ active, title, path, icon, badge, ...other }) {
  const theme = useTheme();
  const isActiveRoot = active(path);

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path ?? "/stop"}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
      {...other}
    >
      <ListItemIconStyle>
        {badge && <Badge sx={{ width: 30, height: 30 }} badgeContent={badge}>
          {icon}
        </Badge>}
        {!badge && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

export default function NavSection({ ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <NavItem
        key="Панель ставок"
        title="Панель ставок"
        path={PATH_DASHBOARD.general.app}
        icon={ICONS.dashboard}
        active={match} />

      <NavItem
        key="Мои ставки"
        title="Мои ставки"
        path={PATH_DASHBOARD.general.myBets}
        icon={ICONS.list}
        // badge={4}
        active={match} />

      <NavItem
        key="Баланс"
        title="Баланс"
        path={PATH_DASHBOARD.general.balance}
        icon={ICONS.clock}
        active={match} />

      <NavItem
        key="FAQ"
        title="FAQ"
        component={Link}
        href="https://vk.com"
        target="_blank"
        rel="noopener noreferrer"
        icon={ICONS.list}
        active={match} />

      {/* {
        navConfig.map((item) => {
          return (<NavItem key={item.title} item={item} active={match} />)
        })
      } */}
      {/* {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
            {items.map((item) => (
              <NavItem key={item.title} item={item} active={match} />
            ))}
          </List>
        );
      })} */}
    </Box>
  );
}
