import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
  isLoading: false,
  error: false,
  currentRound: null,
}

const slice = createSlice({
  name: "rounds",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.currentRound = null;
      state.error = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCurrentRoundSuccess(state, action) {
      state.isLoading = false;
      state.currentRound = action.payload;
    },
    forceChangeRound(state, action) {
      state.isLoading = false;
      state.currentRound = action.payload;
    }
  }
})

export const { forceChangeRound } = slice.actions;

export default slice.reducer;

export function getCurrentRound(assetID, marketID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`/api/v1/game/markets/${marketID}/currentRound`)

      dispatch(slice.actions.getCurrentRoundSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}