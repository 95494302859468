import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Drawer, Typography, Stack, IconButton, Tooltip } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
import useBtcPrice from '../../hooks/useBtcPrice';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import lockFill from '@iconify/icons-eva/lock-fill';
//
import sidebarConfig from './SidebarConfig';
import { Icon } from '@iconify/react';
import paperPlaneOutline from '@iconify/icons-eva/paper-plane-outline';
import { useTheme } from '@material-ui/styles';
import { fBTCFull, fRUB } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: "column",
  alignItems: 'start',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: 'rgb(0,0,0,0.4)',
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor: 'rgb(0,0,0,0.4)'
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const { priceRUB } = useBtcPrice();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
          <AccountStyle spacing={0.5}>
            <Stack direction="row" width="100%">
              <MyAvatar />
              <Box sx={{ ml: 2, mb: 0.5 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user.username}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user.email}
                </Typography>
              </Box>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              Баланс
            </Typography>
            <Stack direction="row">
              <Typography sx={{ color: 'text.secondary' }}>
                BTC&nbsp;
              </Typography>
              <Typography sx={{ color: 'text.primary' }}>
                <b>{fBTCFull(user.balance_btc)}</b>
              </Typography>
            </Stack>
            <Tooltip title={`Курс BTC: ${fRUB(priceRUB)}`}>
              <Stack direction="row">
                <Typography sx={{ color: 'text.secondary' }}>
                  РУБ&nbsp;
                </Typography>
                <Typography sx={{ color: 'text.primary' }}>
                  <b>{fRUB(user.balance_btc * priceRUB)}</b>
                </Typography>
              </Stack>
            </Tooltip>
            <Stack direction="row">
              <Icon icon={lockFill} width={20} height={20} color={theme.palette.text.secondary} />
              <Typography sx={{ color: 'text.secondary', fontSize: 13, pl: 0.5, pt: 0.6 }}>
                BTC&nbsp;{fBTCFull(user.frozen_btc)}
              </Typography>
            </Stack>
          </AccountStyle>
        </Link>
      </Box>


      <NavSection navconfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Link underline="none" to={PATH_DASHBOARD.user.account} sx={{ color: 'text.primary' }}>
          <DocStyle>
            <Typography variant="body2">
              Обсуждай активы вместе с другими пользователями<br /> в телеграм-чате
            </Typography>
            <IconButton style={{ transform: "translate(330%, -10px)", position: "absolute" }} sx={{ bgcolor: 'primary.main' }}>
              <Icon icon={paperPlaneOutline} color='black' />
            </IconButton>
          </DocStyle>
        </Link>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'rgb(0,0,0,0.4)', borderRight: 'none' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'rgb(0,0,0,0.4)', borderRight: 'none' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
