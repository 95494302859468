import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment, Dialog, Button, Typography, FormControlLabel, Checkbox, Link, DialogTitle, DialogContent } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
//
import { MIconButton } from '../../@material-extend';
import axiosInstance from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function RegisterForm({ onClose, onOpen, open, onOpenLogin }) {
  const { register } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [acceptConf, setAcceptConf] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  }

  const handleTapOpen = () => {
    onOpen();
  }

  const isUsernameValid = username => {
    return axiosInstance.get("/api/v1/user-management/validate/username", {
      params: {
        username
      },
      validateStatus: (s) => true,
    })
  }

  const debouncedUsernameCheck = useMemo(() => debounce(isUsernameValid, 500, {
    // leading: true,
  }), []);

  const isEmailValid = email => {
    return axiosInstance.get("/api/v1/user-management/validate/email", {
      params: {
        email
      },
      validateStatus: (s) => true,
    })
  }

  const debouncedEmailCheck = useMemo(() => debounce(isEmailValid, 500, {
    // trailing: true
  }), []);

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().min(3, 'Слишком короткое').max(30, 'Слишком длинное').required('Введите имя пользователя').test({
      name: "username server side check",
      test: async function (email) {
        try {
          const response = await debouncedUsernameCheck(email);

          if (response === null) {
            return true
          }

          if (response.status === 200) {
            return true
          }

          switch (response.data.error) {
            case "empty_username":
              return this.createError({
                message: "Введите имя пользователя",
                path: "username",
              });
            case "long_username":
              return this.createError({
                message: "Слишком длинное",
                path: "username",
              });
            case "username_exists":
              return this.createError({
                message: "Имя пользователя уже занято",
                path: "username",
              })
            default:
              return true;
          }
        } catch (e) {
          return true;
        }
      },
    }),
    email: Yup.string().email('Неверный email').required('Укажите email').test({
      name: "email server side check",
      test: async function (email) {
        try {
          const response = await debouncedEmailCheck(email);
          if (response === null) {
            return true
          }

          if (response.status === 200) {
            return true
          }

          switch (response.data.error) {
            case "invalid_email":
              return this.createError({
                message: "Неверный email",
                path: "email",
              });
            case "email_exists":
              return this.createError({
                message: "Email уже занят",
                path: "email",
              });
            default:
              return true;
          }
        } catch (e) {
          return true;
        }
      },
    }),
    password: Yup.string().min(5, 'Слишком короткий').required('Укажите пароль')
  });

  const errTranslit = {
    short_username: "Слишком короткий",
    long_username: "Слишком длинный",
    empty_email: "Введите почту",
    invalid_email: "Неверная почта",
    short_password: "Слишком короткий",
    username_exists: "Имя пользователя занято",
    email_exists: "Почта уже используется",
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: ''
    },
    isInitialValid: false,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await register(values.username, values.email, values.password);
        enqueueSnackbar('Регистрация успешна', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        setTimeout(() => {
          navigate(PATH_DASHBOARD.root);
        }, 2000)
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(errTranslit[error.response?.data?.error] ?? "Неизвестная ошибка", { variant: 'error' });
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, isValid, getFieldProps } = formik;

  return (
    <>
      <Button variant="contained" onClick={handleTapOpen}>Зарегистрироваться</Button>
      <Dialog onClose={handleClose} open={open} maxWidth='xs'>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              Регистрация
            </DialogTitle>

            <DialogContent>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <TextField
                  fullWidth
                  label="Имя пользователя"
                  {...getFieldProps('username')}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />

                <TextField
                  fullWidth
                  autoComplete="email"
                  type="email"
                  label="Email адрес"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Пароль"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox checked={acceptConf} onChange={(e) => setAcceptConf(e.target.checked)} />}
                  label={<Typography variant='subtitle2'>
                    Регистрируясь, я принимаю <br /> <Link href="https://vk.com">условия использования</Link> и <br /> <Link href="https://vk.com">политику конфиденциальности</Link>
                  </Typography>}
                  labelPlacement="end"
                />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid || !acceptConf} loading={isSubmitting}>
                  Зарегистрироваться
                </LoadingButton>

                <Typography variant="subtitle2">
                  Уже есть профиль?
                </Typography>

                <Button fullWidth size="large" variant="outlined" onClick={onOpenLogin}>
                  Войти
                </Button>
              </Stack>
            </DialogContent>
          </Form>
        </FormikProvider>
      </Dialog >
    </>
  );
}
