import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg viewBox="50 50 569 569" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75.3696 418.533C108.747 408.291 178.985 387.99 217.745 386.465C269.82 384.405 357.867 425.43 412.775 432.599C467.684 439.768 504.612 437.731 504.612 437.731" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M56.7974 424.394C56.7974 424.394 59.6879 423.45 64.7634 421.845" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M489.784 360.484C470.594 355.91 450.884 353.893 431.165 354.487C390.436 355.955 346.407 361.804 291.874 369.406C237.341 377.008 159.764 334.97 106.972 350.572C54.18 366.174 58.1858 370.863 58.1858 370.863" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M504.794 364.718C504.794 364.718 502.746 363.978 499.024 362.908" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M326.731 104.127C326.099 101.545 324.618 99.2489 322.526 97.6076C320.433 95.9663 317.851 95.0742 315.192 95.0742C312.533 95.0742 309.951 95.9663 307.859 97.6076C305.767 99.2489 304.286 101.545 303.653 104.127H326.731Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M443.319 104.127C442.62 101.186 441.032 98.5323 438.773 96.5249C436.513 94.5175 433.69 93.2542 430.687 92.9064C431.072 90.8192 431.266 88.7014 431.268 86.5791C431.257 77.9542 428.021 69.6453 422.194 63.2864C416.367 56.9276 408.371 52.9789 399.78 52.2171C391.189 51.4553 382.624 53.9354 375.769 59.1697C368.914 64.404 364.265 72.0137 362.737 80.5022C359.056 77.62 354.554 75.9823 349.881 75.8256C345.209 75.6688 340.607 77.0011 336.741 79.6301C332.874 82.2591 329.944 86.0487 328.372 90.4519C326.799 94.8551 326.667 99.6439 327.994 104.127H443.319Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M121.368 131.838C121.049 130.524 120.297 129.356 119.234 128.52C118.172 127.685 116.859 127.231 115.507 127.231C114.155 127.231 112.843 127.685 111.78 128.52C110.717 129.356 109.966 130.524 109.646 131.838H121.368Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M180.555 131.837C180.197 130.346 179.389 129 178.242 127.982C177.094 126.965 175.661 126.324 174.137 126.147C174.614 123.857 174.629 121.494 174.179 119.197C173.729 116.901 172.824 114.718 171.518 112.777C170.211 110.836 168.529 109.176 166.571 107.895C164.613 106.613 162.419 105.737 160.117 105.317C157.815 104.897 155.452 104.942 153.168 105.449C150.884 105.956 148.724 106.916 146.816 108.27C144.908 109.625 143.291 111.348 142.059 113.337C140.828 115.327 140.006 117.542 139.644 119.854C137.775 118.392 135.489 117.561 133.117 117.481C130.745 117.402 128.409 118.079 126.447 119.413C124.484 120.748 122.996 122.671 122.198 124.907C121.4 127.142 121.332 129.573 122.005 131.849L180.555 131.837Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M174.592 150.364C174.386 149.539 173.91 148.807 173.24 148.284C172.57 147.76 171.744 147.476 170.894 147.476C170.044 147.476 169.218 147.76 168.548 148.284C167.878 148.807 167.401 149.539 167.195 150.364H174.592Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M211.953 150.364C211.725 149.423 211.215 148.574 210.491 147.931C209.767 147.289 208.863 146.883 207.902 146.768C208.026 146.1 208.087 145.422 208.084 144.743C208.086 141.976 207.052 139.309 205.185 137.268C203.319 135.226 200.755 133.958 197.999 133.714C195.243 133.47 192.496 134.267 190.3 135.948C188.103 137.63 186.616 140.073 186.132 142.797C184.951 141.878 183.509 141.358 182.014 141.31C180.519 141.261 179.047 141.688 177.809 142.529C176.572 143.37 175.632 144.581 175.126 145.988C174.619 147.396 174.572 148.928 174.991 150.364H211.953Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path opacity="0.5" d="M390.709 460.822H389.571C371.557 444.241 321.701 432.372 263.014 432.372C189.158 432.372 129.288 451.251 129.288 474.478C129.288 497.704 189.158 516.584 263.014 516.584C312.153 516.584 355.101 508.231 378.351 495.781C382.345 495.974 386.465 496.077 390.675 496.077C428.161 496.077 458.557 488.111 458.557 478.415C458.557 468.719 428.195 460.822 390.709 460.822Z" fill="#0D0E10" />
        <path d="M349.366 290.827C349.366 290.827 353.178 324.364 370.703 332.751C388.228 341.138 398.152 328.199 398.152 316.364C398.152 304.529 417.964 293.501 432.451 293.501C446.938 293.501 451.126 312.938 444.969 328.939C438.813 344.939 431.302 364 402.749 373.15C374.197 382.3 357.4 370.1 357.4 370.1C357.4 370.1 361.599 378.487 357.4 379.625C353.201 380.763 341.4 367.813 341.4 367.813C341.4 367.813 346.737 379.25 340.637 375.437C334.538 371.625 328.062 349.138 328.062 349.138C328.062 349.138 328.438 357.104 323.51 357.901C318.583 358.697 315.886 341.514 315.886 341.514L347.522 283.214L349.366 290.827Z" fill="white" />
        <path d="M417.498 348.103C415.083 348.103 412.767 349.062 411.06 350.769C409.353 352.476 408.394 354.792 408.394 357.207C408.394 359.621 409.353 361.937 411.06 363.644C412.767 365.351 415.083 366.311 417.498 366.311H417.93C420.835 364.545 423.57 362.514 426.101 360.245C426.444 359.265 426.621 358.234 426.624 357.195C426.623 355.999 426.385 354.814 425.926 353.709C425.466 352.605 424.793 351.601 423.946 350.757C423.098 349.912 422.092 349.243 420.986 348.788C419.879 348.332 418.694 348.1 417.498 348.103Z" fill="#5AC994" />
        <path d="M397.469 321.872C394.92 331.693 385.543 339.829 370.738 332.751C370.094 332.439 369.467 332.094 368.86 331.715C368.016 334.904 368.053 338.263 368.97 341.432C369.886 344.602 371.646 347.462 374.062 349.709C376.478 351.956 379.459 353.504 382.686 354.188C385.913 354.872 389.266 354.666 392.385 353.593C395.505 352.519 398.274 350.619 400.397 348.094C402.521 345.569 403.918 342.514 404.44 339.257C404.962 335.999 404.589 332.661 403.361 329.599C402.134 326.537 400.097 323.866 397.469 321.872Z" fill="#5AC994" />
        <path d="M363.113 372.558C363.14 370.882 362.836 369.216 362.219 367.657C361.602 366.097 360.685 364.674 359.518 363.47C358.351 362.265 356.959 361.302 355.42 360.635C353.881 359.969 352.226 359.612 350.549 359.585C348.873 359.558 347.207 359.862 345.648 360.479C344.088 361.096 342.665 362.013 341.461 363.18C340.256 364.347 339.293 365.739 338.626 367.278C337.96 368.817 337.603 370.472 337.576 372.149C338.371 373.435 339.412 374.552 340.637 375.437C346.737 379.25 341.4 367.813 341.4 367.813C341.4 367.813 353.212 380.775 357.4 379.625C361.588 378.476 357.4 370.1 357.4 370.1C359.185 371.282 361.093 372.266 363.09 373.036C363.09 372.877 363.113 372.718 363.113 372.558Z" fill="#5AC994" />
        <path opacity="0.2" d="M443.137 310.89C436.161 319.766 424.747 357.161 395.591 365.412C366.436 373.662 339.829 354.635 339.829 354.635C339.829 354.635 344.267 350.197 353.144 334.981C355.614 330.743 357.723 326.304 359.448 321.712C351.482 308.796 349.423 290.827 349.423 290.827L347.522 283.214L315.886 341.514C315.886 341.514 318.56 358.663 323.51 357.901C328.461 357.138 328.062 349.138 328.062 349.138C328.062 349.138 334.538 371.625 340.637 375.437C346.737 379.25 341.4 367.813 341.4 367.813C341.4 367.813 353.212 380.775 357.4 379.625C361.588 378.476 357.4 370.1 357.4 370.1C357.4 370.1 374.174 382.3 402.749 373.15C431.325 364.001 438.96 344.95 445.06 328.939C447.391 322.885 448.218 316.356 447.473 309.911C445.572 309.468 444.059 309.707 443.137 310.89Z" fill="black" />
        <path d="M349.366 290.827C349.366 290.827 353.178 324.364 370.703 332.751C388.228 341.138 398.152 328.199 398.152 316.364C398.152 304.529 417.964 293.501 432.451 293.501C446.938 293.501 451.126 312.938 444.969 328.939C438.813 344.939 431.302 364 402.749 373.15C374.197 382.3 357.4 370.1 357.4 370.1C357.4 370.1 361.599 378.487 357.4 379.625C353.201 380.763 341.4 367.813 341.4 367.813C341.4 367.813 346.737 379.25 340.637 375.437C334.538 371.625 328.062 349.138 328.062 349.138C328.062 349.138 328.438 357.104 323.51 357.901C318.583 358.697 315.886 341.514 315.886 341.514L347.522 283.214L349.366 290.827Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M436.104 340.751C434.897 340.751 433.739 340.272 432.885 339.418C432.032 338.564 431.552 337.407 431.552 336.199V264.505H440.656V336.199C440.656 337.407 440.177 338.564 439.323 339.418C438.469 340.272 437.311 340.751 436.104 340.751Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M499.582 179.804H373.264V269.706H499.582V179.804Z" fill="#263238" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M433.783 315.658C433.783 315.658 449.908 306.828 456.611 312.609C463.314 318.39 460.56 327.528 455.393 329.679C450.227 331.829 438.961 334.549 432.872 332.421C426.784 330.293 421.606 321.44 433.783 315.658Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M426.75 292.352C426.75 292.352 446.949 281.302 455.336 288.552C463.723 295.801 460.287 307.215 453.811 309.889C447.336 312.563 433.236 315.989 425.612 313.303C417.987 310.617 411.523 299.601 426.75 292.352Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M389.605 237.478L405.776 199.229H415.484V237.478H419.694V245.557H415.484V255.799H406.755V245.557H389.605V237.478ZM406.755 237.478V216.22L397.776 237.489L406.755 237.478Z" fill="#5AC994" />
        <path d="M423.006 212.806C423.006 203.702 427.774 198.57 436.503 198.57C445.231 198.57 450.011 203.748 450.011 212.806V242.235C450.011 251.339 445.242 256.471 436.503 256.471C427.763 256.471 423.006 251.293 423.006 242.235V212.806ZM431.894 242.804C431.894 246.844 433.68 248.38 436.503 248.38C439.325 248.38 441.111 246.844 441.111 242.804V212.249C441.111 208.209 439.336 206.672 436.503 206.672C433.669 206.672 431.894 208.209 431.894 212.249V242.804Z" fill="#5AC994" />
        <path d="M453.652 237.478L469.823 199.229H479.519V237.478H483.73V245.557H479.519V255.799H470.791V245.557H453.652V237.478ZM470.791 237.478V216.22L461.812 237.489L470.791 237.478Z" fill="#5AC994" />
        <path d="M399.324 323.192C399.324 323.192 395.136 301.855 402.374 289.268C409.612 276.682 427.524 284.716 433.248 298.042C438.972 311.368 435.524 325.468 424.474 325.468C413.424 325.468 411.524 307.932 411.524 307.932" fill="white" />
        <path d="M399.324 323.192C399.324 323.192 395.136 301.855 402.374 289.268C409.612 276.682 427.524 284.716 433.248 298.042C438.972 311.368 435.524 325.468 424.474 325.468C413.424 325.468 411.524 307.932 411.524 307.932" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M430.016 320.529C430.016 320.529 431.154 327.801 428.081 332.968C427.818 333.418 427.403 333.758 426.91 333.928C426.417 334.097 425.879 334.083 425.395 333.889C425.038 333.75 424.725 333.516 424.489 333.214C424.252 332.912 424.102 332.552 424.053 332.171C423.768 329.895 422.983 325.229 421.151 322.168C420.687 321.396 420.442 320.513 420.442 319.613C420.442 318.713 420.687 317.83 421.151 317.058C421.462 316.54 421.885 316.097 422.389 315.763C422.893 315.428 423.465 315.21 424.064 315.124C428.184 314.43 429.663 316.091 430.016 320.529Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M231.458 354.385V434.898C231.458 434.898 207.878 451.809 197.204 456.247C186.53 460.685 169.619 450.022 159.388 458.523C149.158 467.024 152.72 480.236 165.169 481.602C165.169 481.602 165.169 488.726 171.405 491.844C173.345 492.747 175.446 493.253 177.584 493.331C179.723 493.409 181.855 493.058 183.855 492.299C183.855 492.299 184.743 502.973 197.648 502.086C210.553 501.198 213.659 490.956 220.772 480.726C227.884 470.495 232.345 461.152 243.02 459.82C253.694 458.489 260.363 456.259 260.363 443.365C260.363 435.399 252.397 434.91 251.475 426.91C250.553 418.909 251.475 357.492 251.475 357.492L231.458 354.385Z" fill="white" />
        <path d="M240.459 444.469C243.683 444.469 246.297 441.855 246.297 438.631C246.297 435.407 243.683 432.793 240.459 432.793C237.235 432.793 234.621 435.407 234.621 438.631C234.621 441.855 237.235 444.469 240.459 444.469Z" fill="#5AC994" />
        <path d="M239.731 373.264C239.731 372.641 239.608 372.024 239.37 371.448C239.131 370.872 238.782 370.349 238.341 369.908C237.9 369.468 237.377 369.118 236.801 368.88C236.226 368.641 235.609 368.519 234.985 368.519C234.317 368.519 233.656 368.663 233.048 368.939C232.439 369.216 231.897 369.62 231.458 370.123V376.405C231.897 376.908 232.439 377.312 233.048 377.589C233.656 377.865 234.317 378.009 234.985 378.009C236.244 378.009 237.451 377.51 238.341 376.62C239.231 375.73 239.731 374.523 239.731 373.264Z" fill="#5AC994" />
        <path d="M247.754 395.558C245.868 395.632 244.083 396.434 242.775 397.795C241.467 399.156 240.736 400.97 240.736 402.858C240.736 404.746 241.467 406.56 242.775 407.921C244.083 409.282 245.868 410.084 247.754 410.158C248.914 410.152 250.054 409.863 251.077 409.316C251.077 405.299 251.077 400.883 251.077 396.4C250.053 395.856 248.913 395.567 247.754 395.558Z" fill="#5AC994" />
        <path opacity="0.2" d="M260.363 443.353C260.363 435.387 252.397 434.898 251.475 426.898C250.553 418.898 251.475 357.48 251.475 357.48L231.458 354.362V380.206C235.668 382.857 241.142 385.338 246.616 385.031V431.302C246.616 431.302 250.428 441.442 247.89 449.681C245.353 457.92 225.699 457.92 219.361 465.533C213.022 473.146 208.584 488.987 202.291 495.963C200.197 498.242 197.862 500.287 195.326 502.063C196.115 502.108 196.905 502.108 197.693 502.063C210.587 501.164 213.705 490.933 220.817 480.703C227.93 470.472 232.391 461.129 243.065 459.797C253.74 458.466 260.363 456.247 260.363 443.353Z" fill="black" />
        <path d="M231.458 354.385V434.898C231.458 434.898 207.878 451.809 197.204 456.247C186.53 460.685 169.619 450.022 159.388 458.523C149.158 467.024 152.72 480.236 165.169 481.602C165.169 481.602 165.169 488.726 171.405 491.844C173.345 492.747 175.446 493.253 177.584 493.331C179.723 493.409 181.855 493.058 183.855 492.299C183.855 492.299 184.743 502.973 197.648 502.086C210.553 501.198 213.659 490.956 220.772 480.726C227.884 470.495 232.345 461.152 243.02 459.82C253.694 458.489 260.363 456.259 260.363 443.365C260.363 435.399 252.397 434.91 251.475 426.91C250.553 418.909 251.475 357.492 251.475 357.492L231.458 354.385Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M183.855 492.288C183.855 492.288 183.412 476.708 202.564 469.994" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M165.169 481.602C165.169 481.602 165.169 469.152 186.085 464.259" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M185.858 489.75C185.858 489.75 179.679 493.744 177.892 499.446C177.734 499.948 177.761 500.49 177.97 500.973C178.179 501.456 178.556 501.847 179.03 502.075C179.372 502.248 179.755 502.326 180.137 502.3C180.52 502.274 180.888 502.145 181.204 501.927C183.059 500.675 187.11 498.126 190.615 497.477C191.505 497.309 192.333 496.903 193.01 496.302C193.687 495.7 194.189 494.927 194.462 494.063C194.634 493.493 194.679 492.892 194.595 492.302C194.51 491.712 194.298 491.148 193.972 490.649C191.651 487.064 189.42 487.064 185.858 489.75Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M167.457 482.148C167.457 482.148 161.277 486.154 159.434 491.844C159.273 492.345 159.299 492.889 159.508 493.372C159.718 493.856 160.096 494.247 160.572 494.473C160.914 494.644 161.295 494.722 161.677 494.698C162.059 494.674 162.428 494.549 162.745 494.336C164.6 493.073 168.652 490.535 172.157 489.875C173.047 489.708 173.875 489.302 174.553 488.701C175.23 488.1 175.732 487.325 176.003 486.461C176.175 485.891 176.221 485.29 176.136 484.7C176.052 484.11 175.839 483.546 175.514 483.047C173.238 479.485 171.019 479.485 167.457 482.148Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M152.777 469.494C152.777 469.494 146.597 473.488 144.742 479.189C144.584 479.691 144.611 480.233 144.82 480.716C145.03 481.199 145.406 481.59 145.88 481.818C146.223 481.988 146.605 482.064 146.987 482.038C147.369 482.012 147.737 481.885 148.054 481.67C149.909 480.407 153.972 477.869 157.465 477.209C158.357 477.045 159.186 476.64 159.864 476.038C160.542 475.436 161.042 474.661 161.312 473.795C161.486 473.225 161.533 472.624 161.448 472.034C161.364 471.443 161.15 470.879 160.822 470.381C158.558 466.819 156.327 466.819 152.777 469.494Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M301.57 354.385V434.898C301.57 434.898 325.149 451.809 335.824 456.247C346.498 460.685 363.397 450.022 373.628 458.523C383.859 467.024 380.308 480.316 367.847 481.647C367.847 481.647 367.847 488.771 361.622 491.889C359.68 492.792 357.578 493.298 355.438 493.376C353.298 493.454 351.164 493.103 349.161 492.344C349.161 492.344 348.273 503.019 335.38 502.131C322.486 501.244 319.357 491.002 312.244 480.771C305.132 470.54 300.682 461.197 290.008 459.866C279.333 458.535 272.653 456.304 272.653 443.411C272.653 435.445 280.619 434.955 281.552 426.955C282.486 418.955 281.552 357.537 281.552 357.537L301.57 354.385Z" fill="white" />
        <path d="M292.295 434.25C295.519 434.25 298.133 431.636 298.133 428.412C298.133 425.187 295.519 422.574 292.295 422.574C289.071 422.574 286.457 425.187 286.457 428.412C286.457 431.636 289.071 434.25 292.295 434.25Z" fill="#5AC994" />
        <path d="M281.917 388.821C282.804 389.374 283.823 389.679 284.869 389.704C285.914 389.729 286.947 389.472 287.86 388.961C288.772 388.45 289.53 387.704 290.055 386.799C290.581 385.895 290.853 384.866 290.845 383.821C290.836 382.775 290.547 381.751 290.007 380.855C289.468 379.959 288.697 379.225 287.777 378.729C286.856 378.233 285.819 377.993 284.774 378.034C283.73 378.076 282.715 378.398 281.837 378.966C281.86 382.118 281.894 385.441 281.917 388.821Z" fill="#5AC994" />
        <path d="M301.57 396.616C299.731 397.131 298.111 398.233 296.957 399.755C295.803 401.276 295.179 403.133 295.179 405.043C295.179 406.952 295.803 408.809 296.957 410.331C298.111 411.852 299.731 412.954 301.57 413.469V396.616Z" fill="#5AC994" />
        <path opacity="0.2" d="M281.586 357.503C281.586 357.503 281.757 369.167 281.871 382.983C287.654 383.616 293.495 383.463 299.237 382.528C299.237 382.528 296.062 430.062 296.699 436.4C297.336 442.739 325.855 459.229 334.105 461.129C342.356 463.03 356.785 462.017 364.524 466.193C369.258 468.754 371.523 475.502 372.558 480.384C381.059 476.629 382.47 465.749 373.696 458.478C363.454 450.022 346.566 460.697 335.892 456.202C325.217 451.707 301.57 434.898 301.57 434.898V354.385L281.586 357.503Z" fill="black" />
        <path d="M301.57 354.385V434.898C301.57 434.898 325.149 451.809 335.824 456.247C346.498 460.685 363.397 450.022 373.628 458.523C383.859 467.024 380.308 480.316 367.847 481.647C367.847 481.647 367.847 488.771 361.622 491.889C359.68 492.792 357.578 493.298 355.438 493.376C353.298 493.454 351.164 493.103 349.161 492.345C349.161 492.345 348.273 503.019 335.38 502.131C322.486 501.244 319.357 491.002 312.244 480.771C305.132 470.54 300.682 461.197 290.008 459.866C279.333 458.535 272.653 456.304 272.653 443.411C272.653 435.445 280.619 434.955 281.552 426.955C282.486 418.955 281.552 357.537 281.552 357.537L301.57 354.385Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M349.195 492.287C349.195 492.287 349.639 476.708 330.521 470.04" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M367.881 481.602C367.881 481.602 367.881 469.152 346.976 464.259" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M347.192 489.75C347.192 489.75 353.371 493.744 355.215 499.446C355.376 499.941 355.355 500.477 355.157 500.959C354.959 501.44 354.596 501.836 354.134 502.075C353.792 502.248 353.409 502.326 353.027 502.3C352.644 502.274 352.276 502.145 351.96 501.927C350.105 500.675 346.043 498.126 342.549 497.477C341.658 497.313 340.828 496.908 340.15 496.306C339.472 495.704 338.972 494.929 338.703 494.063C338.529 493.493 338.481 492.893 338.564 492.303C338.646 491.713 338.857 491.149 339.181 490.649C341.4 487.064 343.676 487.064 347.192 489.75Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M365.594 482.148C365.594 482.148 371.773 486.154 373.617 491.844C373.782 492.339 373.764 492.877 373.566 493.359C373.367 493.842 373.002 494.237 372.536 494.472C372.194 494.645 371.813 494.724 371.43 494.7C371.048 494.676 370.68 494.55 370.362 494.336C368.507 493.073 364.445 490.535 360.951 489.875C360.059 489.712 359.229 489.307 358.551 488.705C357.873 488.103 357.373 487.327 357.105 486.461C356.93 485.891 356.883 485.289 356.968 484.699C357.052 484.109 357.266 483.545 357.594 483.047C359.813 479.485 362.032 479.485 365.594 482.148Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M380.286 469.494C380.286 469.494 386.465 473.488 388.308 479.189C388.465 479.691 388.436 480.232 388.227 480.715C388.018 481.197 387.643 481.589 387.17 481.818C386.826 481.989 386.442 482.065 386.058 482.039C385.674 482.013 385.304 481.886 384.985 481.67C383.13 480.407 379.079 477.869 375.574 477.209C374.683 477.046 373.855 476.642 373.178 476.04C372.502 475.438 372.004 474.661 371.739 473.795C371.562 473.226 371.513 472.625 371.596 472.035C371.678 471.444 371.89 470.88 372.217 470.381C374.504 466.819 376.678 466.819 380.286 469.494Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M153.311 397.356C153.311 397.356 153.072 387.535 155.712 384.428C158.353 381.321 161.402 395.683 161.209 397.595C161.016 399.507 157.624 402.852 153.311 397.356Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M170.552 393.998C170.552 393.998 170.313 384.189 172.942 381.082C175.571 377.975 178.689 392.326 178.45 394.237C178.211 396.149 174.854 399.506 170.552 393.998Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M151.866 407.245C151.866 407.245 148.748 392.997 156.327 392.098C163.906 391.199 170.142 417.942 170.142 417.942C170.142 417.942 164.793 389.424 174.148 388.536C183.502 387.649 184.39 414.38 184.39 416.611C184.39 418.841 176.424 428.639 164.338 431.314C152.253 433.988 151.866 407.245 151.866 407.245Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M142.068 404.058C142.068 404.058 141.829 394.237 144.457 391.131C147.086 388.024 150.204 402.385 149.965 404.297C149.726 406.209 146.369 409.566 142.068 404.058Z" fill="white" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M187.963 274.93C187.963 274.93 176.822 284.284 170.586 303.88C164.35 323.477 162.119 332.399 168.31 331.067C174.501 329.736 176.777 322.157 176.777 322.157C176.777 322.157 169.198 344.427 173.215 346.214C177.232 348.001 182.569 343.938 182.569 343.938C182.569 343.938 180.828 363.591 184.356 369.384C187.884 375.176 189.249 375.176 189.249 375.176V410.818C189.249 410.818 178.564 408.144 169.653 412.594C160.742 417.043 159.843 423.735 159.843 423.735C159.843 423.735 155.838 402.352 146.927 399.233C138.017 396.115 138.017 415.712 142.91 426.853C147.803 437.994 162.962 442.898 164.304 442.898C165.647 442.898 161.63 446.46 161.63 446.46L173.659 443.342C173.659 443.342 167.866 450.022 171.872 450.022C175.878 450.022 188.908 443.342 188.908 443.342C188.908 443.342 184.458 448.691 189.796 448.691C195.133 448.691 202.723 440.725 202.723 440.725C202.723 440.725 202.723 444.731 207.617 439.837C212.51 434.944 223.219 435.82 225.438 406.835C227.657 377.85 225.438 341.343 225.438 341.343C225.438 341.343 214.695 255.766 187.963 274.93Z" fill="white" />
        <path d="M218.132 346.86C221.063 339.836 217.745 331.765 210.721 328.834C203.697 325.903 195.627 329.221 192.696 336.246C189.765 343.27 193.083 351.34 200.107 354.271C207.131 357.202 215.201 353.884 218.132 346.86Z" fill="#5AC994" />
        <path d="M183.423 432.053C180.03 432.065 176.761 433.327 174.241 435.599C171.721 437.871 170.128 440.993 169.767 444.366L173.75 443.342C173.75 443.342 167.957 450.022 171.963 450.022C175.969 450.022 188.908 443.342 188.908 443.342C188.908 443.342 184.458 448.691 189.795 448.691C192.128 448.691 194.871 447.166 197.238 445.448C197.14 441.853 195.64 438.44 193.058 435.937C190.477 433.434 187.018 432.04 183.423 432.053Z" fill="#5AC994" />
        <path d="M224.081 404.088C227.707 395.256 223.486 385.158 214.655 381.532C205.823 377.906 195.724 382.127 192.099 390.958C188.473 399.79 192.693 409.888 201.525 413.514C210.356 417.14 220.455 412.92 224.081 404.088Z" fill="#5AC994" />
        <path d="M168.356 331.067C173.761 329.929 176.151 324.069 176.697 322.532C175.129 327.227 173.83 332.006 172.805 336.849C175.835 335.649 178.376 333.471 180.025 330.66C181.673 327.849 182.334 324.568 181.903 321.338C181.471 318.108 179.971 315.116 177.642 312.837C175.313 310.558 172.288 309.124 169.05 308.763C164.031 324.945 162.666 332.297 168.356 331.067Z" fill="#5AC994" />
        <path opacity="0.2" d="M187.963 274.93C187.963 274.93 182.137 279.846 176.583 289.974C176.64 302.586 179.125 315.07 183.901 326.743C196.578 356.536 213.693 364.775 213.693 364.775C213.693 364.775 217.494 400.269 210.518 419.285C203.952 437.208 185.005 444.992 161.584 442.125C162.475 442.482 163.403 442.742 164.35 442.898C165.681 442.898 161.676 446.46 161.676 446.46L173.704 443.342C173.704 443.342 167.912 450.022 171.918 450.022C175.923 450.022 188.908 443.342 188.908 443.342C188.908 443.342 184.458 448.691 189.795 448.691C195.133 448.691 202.723 440.725 202.723 440.725C202.723 440.725 202.723 444.731 207.617 439.837C212.51 434.944 223.219 435.82 225.438 406.835C227.657 377.85 225.438 341.343 225.438 341.343C225.438 341.343 214.695 255.766 187.963 274.93Z" fill="black" />
        <path d="M187.963 274.93C187.963 274.93 176.822 284.284 170.586 303.88C164.35 323.477 162.119 332.399 168.31 331.067C174.501 329.736 176.777 322.157 176.777 322.157C176.777 322.157 169.198 344.427 173.215 346.214C177.232 348.001 182.569 343.938 182.569 343.938C182.569 343.938 180.828 363.591 184.356 369.384C187.884 375.176 189.249 375.176 189.249 375.176V410.818C189.249 410.818 178.564 408.144 169.653 412.594C160.742 417.043 159.843 423.735 159.843 423.735C159.843 423.735 155.838 402.352 146.927 399.233C138.017 396.115 138.017 415.712 142.91 426.853C147.803 437.994 162.962 442.899 164.304 442.899C165.647 442.899 161.63 446.46 161.63 446.46L173.659 443.342C173.659 443.342 167.866 450.022 171.872 450.022C175.878 450.022 188.908 443.342 188.908 443.342C188.908 443.342 184.458 448.691 189.796 448.691C195.133 448.691 202.723 440.725 202.723 440.725C202.723 440.725 202.723 444.731 207.617 439.837C212.51 434.944 223.219 435.82 225.438 406.835C227.657 377.85 225.438 341.343 225.438 341.343C225.438 341.343 214.695 255.766 187.963 274.93Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M195.258 230.764C195.258 230.764 182.512 250.36 159.479 256.244C136.446 262.127 116.349 260.17 116.838 261.638C117.328 263.106 125.169 268.011 125.169 268.011C125.169 268.011 117.817 267.51 118.807 269.968C119.797 272.426 126.648 283.692 149.681 289.564C172.714 295.436 186.928 265.063 191.332 251.828C195.736 238.593 199.15 228.795 195.258 230.764Z" fill="white" />
        <path d="M126.671 260.727C126.956 264.077 127.95 267.329 129.588 270.265C131.225 273.202 133.468 275.756 136.168 277.76C138.868 279.764 141.963 281.171 145.248 281.887C148.532 282.604 151.932 282.613 155.221 281.916C158.51 281.218 161.613 279.83 164.325 277.842C167.036 275.853 169.294 273.312 170.948 270.385C172.602 267.458 173.615 264.212 173.92 260.864C174.224 257.516 173.813 254.141 172.714 250.963C168.542 253.272 164.095 255.046 159.479 256.244C148.726 258.86 137.732 260.363 126.671 260.727Z" fill="#5AC994" />
        <path d="M195.258 230.764C195.258 230.764 182.512 250.36 159.479 256.244C136.446 262.127 116.349 260.17 116.838 261.638C117.328 263.106 125.169 268.011 125.169 268.011C125.169 268.011 117.817 267.51 118.807 269.968C119.797 272.426 126.648 283.692 149.681 289.564C172.714 295.436 186.928 265.063 191.332 251.828C195.736 238.593 199.15 228.795 195.258 230.764Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M338.441 228.852C338.441 228.852 351.187 248.46 374.22 254.343C397.253 260.227 417.35 258.258 416.861 259.726C416.371 261.194 408.519 266.099 408.519 266.099C408.519 266.099 415.87 265.609 414.892 268.056C413.913 270.503 407.051 281.78 384.018 287.664C360.985 293.547 346.771 263.163 342.367 249.928C337.963 236.693 334.572 226.895 338.441 228.852Z" fill="white" />
        <path d="M388.604 257.188C386.757 261.865 386.738 267.065 388.551 271.755C390.365 276.445 393.878 280.28 398.391 282.497C410.113 276.727 414.164 269.911 414.892 268.067C415.871 265.621 408.519 266.11 408.519 266.11C408.519 266.11 416.372 261.205 416.861 259.737C417.237 258.576 404.992 259.532 388.604 257.188Z" fill="#5AC994" />
        <path opacity="0.2" d="M367.062 266.52C367.62 261.831 366.732 256.198 365.56 251.328C354.71 246.336 345.36 238.587 338.441 228.852C334.527 226.895 337.952 236.693 342.367 249.928C346.783 263.163 360.985 293.547 384.018 287.664C367.381 287.914 365.799 277.297 367.062 266.52Z" fill="black" />
        <path d="M338.441 228.852C338.441 228.852 351.187 248.46 374.22 254.343C397.253 260.227 417.35 258.258 416.861 259.726C416.371 261.194 408.519 266.099 408.519 266.099C408.519 266.099 415.87 265.609 414.892 268.056C413.913 270.503 407.051 281.78 384.018 287.664C360.985 293.547 346.771 263.163 342.367 249.928C337.963 236.693 334.572 226.895 338.441 228.852Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M215.833 205.272C215.833 205.272 200.151 208.22 191.821 205.272C183.491 202.325 189.374 196.453 185.938 192.049C182.501 187.645 172.282 195.463 171.235 209.688C170.188 223.913 186.427 231.742 203.099 233.21L222.695 205.272H215.833Z" fill="#5AC994" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M197.045 206.354C195.327 206.203 193.632 205.86 191.992 205.33C188.44 207.658 185.627 210.952 183.883 214.825C182.139 218.698 181.538 222.988 182.148 227.191C183.371 227.89 184.633 228.521 185.927 229.08C185.153 226.041 182.956 213.375 197.045 206.354Z" fill="#263238" />
        <path d="M187.076 198.229C179.883 199.856 174.683 202.781 171.53 207.082C171.405 207.924 171.303 208.789 171.235 209.688C171.105 211.549 171.289 213.419 171.781 215.219C172.044 213.407 172.667 211.666 173.613 210.098C176.082 206.081 180.714 203.27 187.417 201.665C187.073 200.554 186.956 199.385 187.076 198.229Z" fill="#263238" />
        <path d="M321.291 205.272C321.291 205.272 336.984 208.22 345.315 205.272C353.645 202.325 347.761 196.453 351.187 192.049C354.612 187.645 364.911 195.463 365.89 209.688C366.868 223.913 350.697 231.742 334.026 233.21L314.429 205.272H321.291Z" fill="#5AC994" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M340.091 206.354C341.809 206.203 343.504 205.86 345.144 205.33C348.696 207.658 351.509 210.952 353.253 214.825C354.997 218.698 355.598 222.988 354.988 227.191C353.765 227.89 352.503 228.521 351.21 229.08C351.972 226.041 354.18 213.375 340.091 206.354Z" fill="#263238" />
        <path d="M350.06 198.229C357.241 199.856 362.453 202.781 365.605 207.082C365.73 207.924 365.833 208.789 365.89 209.688C366.023 211.549 365.842 213.418 365.355 215.219C365.092 213.407 364.469 211.666 363.523 210.098C361.053 206.081 356.41 203.27 349.719 201.665C350.063 200.554 350.179 199.385 350.06 198.229Z" fill="#263238" />
        <path d="M268.17 370.009C314.302 370.009 351.699 327.858 351.699 275.863C351.699 223.867 314.302 181.716 268.17 181.716C222.038 181.716 184.641 223.867 184.641 275.863C184.641 327.858 222.038 370.009 268.17 370.009Z" fill="white" />
        <path d="M228.044 204.999C230.121 205.004 232.166 204.486 233.991 203.493C235.815 202.501 237.361 201.066 238.486 199.319C239.612 197.573 240.28 195.573 240.43 193.501C240.58 191.429 240.206 189.353 239.344 187.463C231.725 190.641 224.636 194.966 218.325 200.288C219.485 201.755 220.962 202.941 222.645 203.757C224.328 204.573 226.173 204.998 228.044 204.999Z" fill="#5AC994" />
        <path d="M227.316 339.34C227.316 329.258 220.123 321.132 211.258 321.132C208.749 321.179 206.288 321.838 204.091 323.052C201.893 324.265 200.025 325.997 198.649 328.097C204.455 337.996 212.094 346.698 221.159 353.736C223.139 351.904 224.711 349.675 225.772 347.194C226.832 344.714 227.359 342.038 227.316 339.34Z" fill="#5AC994" />
        <path d="M274.042 365.617C280.088 365.617 284.989 361.367 284.989 356.126C284.989 350.884 280.088 346.635 274.042 346.635C267.996 346.635 263.094 350.884 263.094 356.126C263.094 361.367 267.996 365.617 274.042 365.617Z" fill="#5AC994" />
        <path d="M218.553 267.055C218.553 250.269 207.059 236.556 192.607 235.703C187.31 248.427 184.602 262.08 184.641 275.863C184.641 283.333 185.431 290.783 186.996 298.088C188.375 298.33 189.773 298.451 191.173 298.452C206.297 298.452 218.553 284.398 218.553 267.055Z" fill="#5AC994" />
        <path d="M344.754 303.421C347.079 297.84 344.44 291.43 338.859 289.105C333.278 286.779 326.869 289.418 324.543 295C322.218 300.581 324.857 306.99 330.438 309.316C336.019 311.641 342.428 309.002 344.754 303.421Z" fill="#5AC994" />
        <path d="M339.75 318.89C339.754 320.128 340.109 321.34 340.774 322.384C342.412 319.15 343.874 315.829 345.156 312.438C343.641 312.71 342.271 313.505 341.283 314.684C340.295 315.863 339.752 317.352 339.75 318.89Z" fill="#5AC994" />
        <path d="M335.824 331.033C331.871 329.25 327.473 328.701 323.203 329.459C318.934 330.217 314.993 332.247 311.897 335.282C308.8 338.317 306.692 342.216 305.848 346.47C305.005 350.723 305.465 355.132 307.169 359.119C318.761 352.095 328.569 342.482 335.824 331.033Z" fill="#5AC994" />
        <path d="M330.669 213.432C327.19 217.318 324.897 222.118 324.06 227.266C323.223 232.413 323.877 237.693 325.946 242.48C328.014 247.267 331.409 251.363 335.731 254.282C340.053 257.201 345.12 258.821 350.333 258.952C347.746 242.356 340.979 226.692 330.669 213.432Z" fill="#5AC994" />
        <path opacity="0.2" d="M314.088 197.25C319.778 205.546 328.461 221.352 334.697 247.504C344.848 289.974 336.598 307.715 336.598 307.715L331.533 295.038C331.533 295.038 333.434 310.89 327.744 320.404C322.054 329.918 317.889 337.28 315.84 341.514C313.792 345.747 313.792 335.608 313.792 335.608C313.792 335.608 309.081 349.207 305.416 350.652C301.752 352.097 290.975 357.799 290.975 357.799L291.601 350.197C291.601 350.197 275.76 356.535 266.884 356.535C258.007 356.535 235.816 353.998 223.776 346.384C216.948 342.071 207.025 335.084 199.742 329.861C214.843 354.134 239.845 370.009 268.147 370.009C314.27 370.009 351.676 327.903 351.676 275.863C351.699 243.008 336.757 214.092 314.088 197.25Z" fill="black" />
        <path d="M268.17 370.009C314.302 370.009 351.699 327.858 351.699 275.863C351.699 223.867 314.302 181.716 268.17 181.716C222.038 181.716 184.641 223.867 184.641 275.863C184.641 327.858 222.038 370.009 268.17 370.009Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M262.81 185.813C262.81 185.813 262.366 184.481 254.787 181.807C247.208 179.133 238.752 182.695 243.202 179.133C247.652 175.571 262.81 175.127 265.484 177.801C268.158 180.475 257.017 173.784 257.017 169.334C257.017 164.885 269.945 167.058 273.951 170.666C276.075 172.426 277.884 174.535 279.299 176.902C279.299 176.902 278.856 160.424 286.867 156.418C294.879 152.412 301.126 160.435 297.564 164.885C294.002 169.334 286.423 176.914 286.423 176.914C286.423 176.914 304.688 170.233 303.801 177.813C302.913 185.392 284.637 185.779 284.637 185.779" fill="white" />
        <path d="M262.81 185.813C262.81 185.813 262.366 184.481 254.787 181.807C247.208 179.133 238.752 182.695 243.202 179.133C247.652 175.571 262.81 175.127 265.484 177.801C268.158 180.475 257.017 173.784 257.017 169.334C257.017 164.885 269.945 167.058 273.951 170.666C276.075 172.426 277.884 174.535 279.299 176.902C279.299 176.902 278.856 160.424 286.867 156.418C294.879 152.412 301.126 160.435 297.564 164.885C294.002 169.334 286.423 176.914 286.423 176.914C286.423 176.914 304.688 170.233 303.801 177.813C302.913 185.392 284.637 185.779 284.637 185.779" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M270.161 275.043C283.077 275.043 293.547 264.176 293.547 250.77C293.547 237.364 283.077 226.496 270.161 226.496C257.246 226.496 246.775 237.364 246.775 250.77C246.775 264.176 257.246 275.043 270.161 275.043Z" fill="#263238" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M270.161 271.8C283.077 271.8 293.547 261.33 293.547 248.414C293.547 235.499 283.077 225.028 270.161 225.028C257.246 225.028 246.775 235.499 246.775 248.414C246.775 261.33 257.246 271.8 270.161 271.8Z" fill="#5AC994" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M274.872 259.723C281.12 257.119 284.073 249.942 281.469 243.695C278.864 237.447 271.688 234.493 265.44 237.098C259.192 239.703 256.239 246.879 258.843 253.127C261.448 259.374 268.624 262.328 274.872 259.723Z" fill="#263238" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M241.87 205.864C241.87 205.864 228.954 208.14 227.167 213.443C225.381 218.747 235.133 219.68 235.133 219.68C235.133 219.68 223.548 229.478 228.897 233.04C234.246 236.602 244.488 223.242 244.488 223.242C244.488 223.242 256.516 232.596 268.989 232.596C281.461 232.596 293.945 222.798 293.945 222.798C293.945 222.798 304.631 234.826 308.648 232.596C312.665 230.366 301.57 216.118 301.57 216.118C301.57 216.118 311.812 218.394 303.356 210.325C301.075 208.027 298.209 206.397 295.068 205.61C291.927 204.824 288.63 204.912 285.535 205.864" fill="white" />
        <path d="M241.87 205.864C241.87 205.864 228.954 208.14 227.167 213.443C225.381 218.747 235.133 219.68 235.133 219.68C235.133 219.68 223.548 229.478 228.897 233.04C234.246 236.602 244.488 223.242 244.488 223.242C244.488 223.242 256.516 232.596 268.989 232.596C281.461 232.596 293.945 222.798 293.945 222.798C293.945 222.798 304.631 234.826 308.648 232.596C312.665 230.366 301.57 216.118 301.57 216.118C301.57 216.118 311.812 218.394 303.356 210.325C301.075 208.027 298.209 206.397 295.068 205.61C291.927 204.824 288.63 204.912 285.535 205.864" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M237.842 278.047C231.821 281.836 226.661 286.845 222.695 292.75C215.56 302.992 217.79 309.229 221.352 309.672C224.914 310.116 236.943 294.526 236.943 294.526C236.943 294.526 231.15 309.229 238.286 308.341C245.421 307.453 253.876 290.964 253.876 290.964C253.876 290.964 253.432 301.206 258.781 300.762C264.13 300.318 270.366 282.497 270.366 282.497C270.366 282.497 273.484 300.318 278.833 300.318C284.181 300.318 284.614 289.177 284.614 289.177C284.614 289.177 290.861 308.785 296.199 304.779C301.536 300.773 297.985 287.846 297.985 287.846C297.985 287.846 302.89 314.133 309.57 309.229C316.25 304.324 306.452 283.396 299.328 275.817" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M296.574 331.511C295.32 331.511 294.09 331.173 293.012 330.532C291.935 329.891 291.05 328.971 290.452 327.869C290.122 327.289 283.453 315.601 270.184 315.601C256.915 315.601 246.684 328.29 246.593 328.415C245.439 329.81 243.787 330.701 241.988 330.901C240.188 331.1 238.382 330.592 236.95 329.484C235.518 328.375 234.573 326.754 234.315 324.962C234.057 323.17 234.505 321.348 235.566 319.88C236.146 319.141 249.928 301.672 270.184 301.672C291.954 301.672 302.287 320.449 302.708 321.257C303.27 322.318 303.549 323.506 303.518 324.706C303.486 325.906 303.146 327.078 302.529 328.108C301.912 329.138 301.04 329.991 299.997 330.585C298.953 331.179 297.775 331.494 296.574 331.499V331.511Z" fill="#263238" />
        <path d="M238.832 316.25L249.45 319.926L248.232 308.603C244.838 310.814 241.687 313.377 238.832 316.25Z" fill="white" />
        <path d="M292.534 309.536L291.771 322.157L302.173 320.313C299.595 316.199 296.336 312.555 292.534 309.536Z" fill="white" />
        <path d="M295.777 322.157L288.949 325.571C289.504 326.3 290.017 327.06 290.486 327.847C291.084 328.949 291.968 329.869 293.046 330.51C294.124 331.151 295.354 331.489 296.608 331.488C297.283 331.482 297.954 331.378 298.599 331.181L295.777 322.157Z" fill="white" />
        <path d="M246.593 328.416C247.308 327.56 248.067 326.744 248.869 325.969L240.118 321.258L237.842 330.305C239.29 331.069 240.961 331.298 242.561 330.953C244.162 330.607 245.589 329.709 246.593 328.416Z" fill="white" />
        <path d="M296.574 331.511C295.32 331.511 294.09 331.173 293.012 330.532C291.935 329.891 291.05 328.971 290.452 327.869C290.122 327.289 283.453 315.601 270.184 315.601C256.915 315.601 246.684 328.29 246.593 328.415C245.439 329.81 243.787 330.701 241.988 330.901C240.188 331.1 238.382 330.592 236.95 329.484C235.518 328.375 234.573 326.754 234.315 324.962C234.057 323.17 234.505 321.348 235.566 319.88C236.146 319.141 249.928 301.672 270.184 301.672C291.954 301.672 302.287 320.449 302.708 321.257C303.27 322.318 303.549 323.506 303.518 324.706C303.486 325.906 303.146 327.078 302.529 328.108C301.912 329.138 301.04 329.991 299.997 330.585C298.953 331.179 297.775 331.494 296.574 331.499V331.511Z" stroke="#263238" stroke-width="1.20355" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M266.918 241.893C266.918 243.244 266.517 244.564 265.767 245.687C265.017 246.81 263.95 247.685 262.703 248.202C261.455 248.718 260.082 248.854 258.758 248.59C257.433 248.327 256.217 247.676 255.262 246.722C254.307 245.767 253.656 244.55 253.393 243.226C253.129 241.901 253.265 240.528 253.781 239.28C254.298 238.033 255.173 236.966 256.296 236.216C257.419 235.466 258.739 235.065 260.09 235.065C261.901 235.065 263.637 235.785 264.918 237.065C266.198 238.346 266.918 240.083 266.918 241.893Z" fill="white" />
      </svg>
    </Box>
  );
}
