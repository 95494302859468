import { Box } from '@material-ui/core';

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }} >
      <svg
        width="100%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 465 469"
      >
        <path
          d="M417.44 375.57c2.08-12.03 3.11-24.42 3.11-37 0-63.14-26.32-120.16-68.63-160.65-21.61-20.7-47.38-37.06-76.02-47.69-1.34-.55-2.69-1.04-4.03-1.53-1.65-.61-3.3-1.16-5.01-1.71-.12 0-.24-.06-.37-.12-1.34-.43-2.63-.86-3.97-1.16-9.34-2.5-19.23-3.85-29.37-3.85A219.437 219.437 0 0 1 76.8 57.58L20.01.79 230.6.3c21.49 0 42.31 2.87 62.04 8.3 99.22 27.17 172.13 118.03 172.13 225.86-.01 53-17.59 101.85-47.33 141.11Z"
          fill="url(#a)"
        />
        <path
          d="M121.67 185.65v282.98C54.56 468.63.16 414.23.16 347.12V20.57l90.65 90.61a105.275 105.275 0 0 1 30.86 74.47Z"
          fill="url(#b)"
        />
        <path
          d="M339.84 270.49c-27.9 94.4-101.85 169.07-195.82 198.14h-2.44v-29.92c0-50.58 41.01-91.59 91.59-91.59 49.64 0 91.71-32.06 106.67-76.63Z"
          fill="url(#c)"
        />
        <path
          d="M420.56 338.57c0 12.58-1.04 24.97-3.11 37-42.68 56.54-110.52 93.05-186.84 93.05h-86.58C238 439.56 311.94 364.88 339.85 270.48c1.16-3.36 2.14-6.78 2.93-10.32.24-1.04.55-2.14.73-3.18 1.47-7.27 2.26-14.84 2.26-22.53 0-47.08-28.88-87.44-69.85-104.23 28.64 10.62 54.4 26.99 76.02 47.69 42.3 40.5 68.62 97.52 68.62 160.66Z"
          fill="url(#d)"
        />
        <defs>
          <linearGradient
            id="a"
            x1={112.574}
            y1={-63.066}
            x2={479.757}
            y2={313.22}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.024} stopColor="#20322C" />
            <stop offset={0.542} stopColor="#5AC994" />
            <stop offset={1} stopColor="#ABE4C9" />
          </linearGradient>
          <linearGradient
            id="b"
            x1={-24.6}
            y1={385.339}
            x2={254.973}
            y2={-74.759}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.024} stopColor="#20322C" />
            <stop offset={0.542} stopColor="#5AC994" />
            <stop offset={1} stopColor="#ABE4C9" />
          </linearGradient>
          <linearGradient
            id="c"
            x1={406.288}
            y1={371.105}
            x2={48.868}
            y2={367.765}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.024} stopColor="#20322C" />
            <stop offset={0.542} stopColor="#5AC994" />
            <stop offset={1} stopColor="#ABE4C9" />
          </linearGradient>
          <linearGradient
            id="d"
            x1={449.955}
            y1={199.707}
            x2={166.039}
            y2={372.526}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.024} stopColor="#20322C" />
            <stop offset={0.542} stopColor="#5AC994" />
            <stop offset={1} stopColor="#ABE4C9" />
          </linearGradient>
        </defs>
      </svg>
    </ Box >
  )
}

// import PropTypes from 'prop-types';
// // material
// import { useTheme } from '@material-ui/core/styles';
// import { Box } from '@material-ui/core';

// // ----------------------------------------------------------------------

// Logo.propTypes = {
//   sx: PropTypes.object
// };

// export default function Logo({ sx }) {
//   const theme = useTheme();
//   const PRIMARY_LIGHT = theme.palette.primary.light;
//   const PRIMARY_MAIN = theme.palette.primary.main;
//   const PRIMARY_DARK = theme.palette.primary.dark;

//   return (
//     <Box sx={{ width: 40, height: 40, ...sx }}>
//       <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
//         <defs>
//           <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
//             <stop offset="0%" stopColor={PRIMARY_DARK} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//           <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
//             <stop offset="0%" stopColor={PRIMARY_LIGHT} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//           <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
//             <stop offset="0%" stopColor={PRIMARY_LIGHT} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//         </defs>
//         <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
//           <path
//             fill="url(#BG1)"
//             d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
//           />
//           <path
//             fill="url(#BG2)"
//             d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
//           />
//           <path
//             fill="url(#BG3)"
//             d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
//           />
//         </g>
//       </svg>
//     </Box>
//   );
// }
