import { createContext, useState, useEffect } from "react";
import axios from "axios";

const initialState = {
  priceRUB: 0,
};

const BtcPriceContext = createContext(initialState);

function BtcPriceProvider({ children }) {
  const [priceRUB, setPriceRUB] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/price?symbol=BTCRUB"
        );

        setPriceRUB(parseFloat(response.data.price));
      } catch (e) {
        console.log(`Binance price error: ${e}`);
      }
    };

    const interval = setInterval(async () => {
      await fetch();
    }, 30000);

    fetch();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <BtcPriceContext.Provider value={{ priceRUB }}>
      {children}
    </BtcPriceContext.Provider>
  );
}

export { BtcPriceProvider, BtcPriceContext };
