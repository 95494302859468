// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value/ 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_REGULAR = 'Gilroy-Regular, sans-serif';
const FONT_BOLD = 'Gilroy-Bold, sans-serif';

const typography = {
  fontFamily: FONT_REGULAR,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(42),
    ...responsiveFontSizes({ sm: 54, md: 60, lg: 66 })
  },
  h2: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 42, md: 46, lg: 50 })
  },
  h3: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 34 })
  },
  h4: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ sm: 22, md: 26, lg: 26 })
  },
  h5: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 21, md: 22, lg: 22 })
  },
  h6: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 })
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(16)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(16)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  overline: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    letterSpacing: 1.1,
    textTransform: 'uppercase'
  },
  button: {
    fontFamily: FONT_BOLD,
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(16),
    textTransform: 'none',
    // textTransform: 'capitalize'
  }
};

export default typography;
