import axiosInstance from "src/utils/axios";

const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
  isMarketsLoading: false,
  isAssetsLoading: false,

  marketsError: false,
  assetsError: false,
  selectedMarket: false,
  selectedAsset: false,
  markets: [],
  assets: [],
}

const slice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    startLoadingMarkets(state) {
      state.isMarketsLoading = true;
      state.markets = [];
      state.error = false;
    },
    startLoadingAssets(state) {
      state.isAssetsLoading = true;
      state.assets = [];
      state.error = false;
    },
    hasAssetsError(state, action) {
      state.isLoading = false;
      state.assetsError = action.payload;
    },
    hasMarketsError(state, action) {
      state.isLoading = false;
      state.marketsError = action.payload;
    },
    getAssetsSuccess(state, action) {
      state.isLoading = false;
      state.assets = action.payload;
      if (action.payload && action.payload.length > 0) {
        state.selectedAsset = action.payload[0];
      }
    },
    getMarketsSuccess(state, action) {
      state.isLoading = false;
      state.markets = action.payload;

      if (action.payload && action.payload.length > 0) {
        state.selectedMarket = action.payload[0];
      }
    },
    selectAsset(state, action) {
      state.selectedAsset = action.payload;
    },
    selectMarket(state, action) {
      state.selectedMarket = action.payload;
    }
  }
})

export default slice.reducer;

export const { selectAsset, selectMarket } = slice.actions;

export function getMarkets(asset_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMarkets());
    try {
      const response = await axiosInstance.get(`/api/v1/game/assets/${asset_id}/markets`)

      dispatch(slice.actions.getMarketsSuccess(response.data))
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasMarketsError(error));
    }
  }
}

export function getAssets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingAssets());
    try {
      const response = await axiosInstance.get("/api/v1/game/assets")

      dispatch(slice.actions.getAssetsSuccess(response.data))
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasAssetsError(error));
    }
  }
}